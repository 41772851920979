/** @format */

import _ from "lodash";
import { REGEX } from "../constants/regex";

export const validateEmail = (email) => {
  return REGEX.EMAIL.test(email.trim());
};

export const validatePassword = (password) => {
  return REGEX.PASSWORD.test(password);
};
export const validateUrl = (url) => {
  return REGEX.URL.test(url);
};
export const validateConfirmPassword = (password, confirmPassword) => {
  return password === confirmPassword && confirmPassword.length > 0;
};

export const validateContact = (contact) => {
  return REGEX.CONTACT.test(contact);
};

export const validateName = (name) => {
  return REGEX.ALPHA_NUMERIC.test(name.trim()) && name.trim().length <= 20;
};

export const validateFax = (fax) => {
  return REGEX.FAX.test(fax);
};

export const validateUsernameLength = (name) => {
  if (name?.length > 50) {
    return true;
  } else {
    return false;
  }
};

export const validateEmailLength = (email) => {
  if (email?.length > 150) {
    return true;
  } else {
    return false;
  }
};

export const validateUserName = (name) => {
  if (!name || name.trim() === "") {
    return false;
  } else {
    return REGEX.USERNAME.test(name);
  }
};

export const validateBlankSpace = (name) => {
  return REGEX.NOT_BLANK_SPACE.test(name);
};

export const validateOnlyChar = (value) => {
  return REGEX.ONLY_CHAR.test(value);
};

export const validateOnlyDigits = (value) => {
  return REGEX.ONLY_NO.test(value);
};

export const validatePinCode = (value) => {
  return REGEX.PINCODE.test(value);
};

export const validateURLAddress = (value) => {
  return REGEX.URL.test(value);
};

export const validateOnlyTenDigits = (value) => {
  return REGEX.ONLY_TEN_DIGIT.test(value);
};

export const validateHospitalName = (value) => {
  const hospitalName = value.trim();
  return (
    REGEX.ALPHA_NUMERIC.test(hospitalName) &&
    !REGEX.ONLY_NO.test(hospitalName) &&
    !REGEX.SPECIAL_CHAR.test(hospitalName)
  );
};

export const validateAdress = (value) => {
  const address = value.trim();
  return (
    REGEX.ALPHA_NUMERIC.test(address) &&
    !REGEX.ONLY_NO.test(address) &&
    !REGEX.SPECIAL_CHAR.test(address)
  );
};



export const validateChoiceLength = (choice, checkType = "string") => {
  if (checkType === "string") {
    return (
      choice?.split(" ")?.join("")?.length >= 2 &&
      choice?.split(" ")?.join("").length <= 30
    );
  }
  return choice > 0 && choice <= 1000;
};

export const validateAlphaNumeric = (str) => /^[0-9]*$/gi.test(str);

export const validateReferalCountLength = (choice) => {
  return choice > 0 && choice <= 1000;
};

export const validateValue = (value) => {
  if (!value || value?.trim() === "") {
    return false;
  } else {
    return value.length !== 0 && value.trim.length <= 15;
  }
};

export const validateInput = (value) => {
  return REGEX.ALPHA_NUMERIC.test(value) || value.length >= 0;
};

export const validateDropdown = (value) => {
  if (_.isEmpty(value)) {
    return false;
  } else {
    return value.length !== 0;
  }
};
