import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import chevron from "../../assets/icons/chevron.svg";
import { sosColors } from "../../constants/theme/colors";
import { Box } from "@mui/material";

export interface Props {
  title: string;
  children: any;
  isValue?: boolean;
}

const FilterAccordion = ({
  title = "",
  children = <></>,
  isValue = false,
}: Props) => {
  return (
    <div>
      <Accordion
        // disabled={true}
        sx={{
          "&.MuiAccordion-root": {
            boxShadow: "none",
          },
          "&.Mui-disabled": {
            backgroundColor: "transparent",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<img src={chevron} alt="open" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            padding: "0px 16px 0px 8px",
            "& .MuiAccordionSummary-content": {
              alignItems: "center",
            },
          }}
        >
          <Typography variant="regular_13px" color={sosColors["secondary"]}>
            {title}
          </Typography>
          {isValue && (
            <Box
              sx={{
                backgroundColor: sosColors["ascent1"],
                height: "8px",
                width: "8px",
                borderRadius: "20px",
                mt: 0.5,
                ml: 1,
              }}
            ></Box>
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};
export default FilterAccordion;
