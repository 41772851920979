import React, { useEffect } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import { ROUTE_PATH } from "./utils/routes";
import Leads from "./pages/Leads";
import Settings from "./pages/Settings";
import LeadDetails from "./pages/LeadDetails";
import SendEmail from "./pages/ForgotPassword/SendEmail";
import ResetPassword from "./pages/ForgotPassword/ResetPassword";

function App() {
  const isLoggedIn = localStorage.getItem("asg_access_token");
  return (
    <div className="App">
      {isLoggedIn ? (
        <Routes>
          <Route path={ROUTE_PATH.leads} element={<Leads />} />
          <Route path={`${ROUTE_PATH.leads}/:id`} element={<LeadDetails />} />
          <Route path={ROUTE_PATH.settings} element={<Settings />} />
          <Route path={"*"} element={<Navigate replace to="/leads" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path={ROUTE_PATH.login} element={<Login />} />
          <Route path={ROUTE_PATH.forgot_password} element={<SendEmail />} />
          <Route path={ROUTE_PATH.reset_password} element={<ResetPassword />} />
          <Route path={"*"} element={<Navigate replace to="/" />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
