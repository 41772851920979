import React, { useEffect, useState } from "react";
import DialogBox from "../DialogBox";
import { validateEmail, validateName } from "../../utils/validation";
import { addUser, getAllUsers, updateUser } from "../../services";
import { saveUsers } from "../../store/reducers/userSlice";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
} from "@mui/material";
import SubmitButton from "../SubmitButton";
import { InputField } from "../../constants/InputField/input";
import { useAppDispatch } from "../../store/hooks";
import { toast } from "react-toastify";
import _ from "lodash";
import grey_email from "../../assets/icons/grey_email.svg";
import blue_email from "../../assets/icons/blue_email.svg";
interface Props {
  open: boolean;
  handleClose: Function;
  isEditable: boolean;
  editData?: Object;
}

export interface UserState {
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  id?: string;
  isValidFirstName: boolean;
  isValidLastName: boolean;
  isValidEmail: boolean;
  isValidCompanyName: boolean;
}

const AddSalesPerson = ({
  open = false,
  handleClose = () => {},
  isEditable = false,
  editData = {},
}: Props) => {
  const dispatch = useAppDispatch();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [userFormData, setUserFormData] = React.useState<UserState>({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    isValidFirstName: true,
    isValidLastName: true,
    isValidEmail: true,
    isValidCompanyName: true,
  });
  const [userFormLoader, setUserFormLoader] = React.useState<boolean>(false);

  useEffect(() => {
    if (
      userFormData?.first_name.trim().length > 0 &&
      userFormData?.last_name.trim().length &&
      userFormData?.email.trim().length &&
      userFormData?.isValidFirstName === true &&
      userFormData?.isValidLastName === true &&
      userFormData?.isValidEmail === true &&
      userFormData?.isValidCompanyName === true
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [userFormData]);

  useEffect(() => {
    if (isEditable && !_.isEmpty(editData)) {
      let data: any = {
        ...editData,
        isValidFirstName: true,
        isValidLastName: true,
        isValidEmail: true,
        isValidCompanyName: true,
      };
      setUserFormData(data);
    }
  }, [isEditable, editData, open]);

  const handleUserFormChange = (e: any) => {
    e.preventDefault();
    const form: any = { ...userFormData };
    form[e.target.name] = e.target.value;
    setUserFormData(form);
  };

  const clearUserFormData = () => {
    setUserFormData({
      first_name: "",
      last_name: "",
      email: "",
      company_name: "",
      isValidFirstName: true,
      isValidLastName: true,
      isValidEmail: true,
      isValidCompanyName: true,
    });
  };

  const getUserData = () => {
    getAllUsers()
      .then((response: any) => {
        if (response.status === 200 && response.data.status_code === 200) {
          dispatch(saveUsers(response.data.users));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUserFormSubmit = () => {
    setUserFormLoader(true);
    const form: any = { ...userFormData };
    form.isValidEmail = validateEmail(form.email);
    form.isValidFirstName = validateName(form.first_name);
    form.isValidLastName = validateName(form.last_name);
    if (form.isValidEmail && form.isValidFirstName && form.isValidLastName) {
      let payload = {
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email,
        company_name: form.company_name,
      };
      if (!isEditable) {
        addUser(payload)
          .then((response: any) => {
            if (response.status === 201 && response.data.status_code === 201) {
              toast.success("User added successfully!");
              handleClose();
              setUserFormLoader(false);
              getUserData();
              clearUserFormData();
            } else {
              setUserFormLoader(false);
              toast.error(response.data.message);
            }
          })
          .catch((error: any) => {
            setUserFormLoader(false);
            console.log(error);
          });
      } else {
        updateUser({ payload: payload, id: userFormData?.id })
          .then((response: any) => {
            if (response.status === 201 && response.data.status_code === 201) {
              toast.success("User updated successfully!");
              handleClose();
              setUserFormLoader(false);
              getUserData();
              clearUserFormData();
            } else {
              setUserFormLoader(false);
              toast.error(response.data.message);
            }
          })
          .catch((error: any) => {
            setUserFormLoader(false);
            console.log(error);
          });
      }
    } else {
      setUserFormLoader(false);
      setUserFormData(form);
    }
  };

  const handleBlur = (e: any) => {
    const data: UserState = { ...userFormData };
    switch (e.target.name) {
      case "first_name": {
        data.isValidFirstName = validateName(e.target.value);
        break;
      }
      case "last_name": {
        data.isValidLastName = validateName(e.target.value);
        break;
      }
      case "email": {
        data.isValidEmail = validateEmail(e.target.value);
        break;
      }
      case "company_name": {
        data.isValidCompanyName = e.target.value.length <= 15;
        break;
      }
    }
    setUserFormData(data);
  };

  return (
    <div>
      <DialogBox
        title={isEditable ? "Edit Details" : "Add New Sales Person"}
        open={open}
        handleClose={() => {
          clearUserFormData();
          handleClose();
        }}
      >
        <DialogContent sx={{ p: 5 }}>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={6}>
              <InputField
                sx={{ height: "5px" }}
                onChange={handleUserFormChange}
                name="first_name"
                isRequired={true}
                isLabel={true}
                isValid={userFormData.isValidFirstName}
                onBlur={handleBlur}
                fullWidth={true}
                message="Please enter a first name"
                value={userFormData.first_name}
                label="First Name"
                placeholder="Enter name here"
                height="20px"
                labelVariant="regular_14px"
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                isRequired={true}
                onChange={handleUserFormChange}
                name="last_name"
                fullWidth={true}
                value={userFormData.last_name}
                isLabel={true}
                isValid={userFormData.isValidLastName}
                onBlur={handleBlur}
                message="Please enter a last name"
                label="Last Name"
                placeholder="Enter name here"
                height="20px"
                labelVariant="regular_14px"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent={"center"} sx={{ my: 2 }}>
            <Grid item xs={6}>
              <InputField
                isRequired={true}
                onChange={handleUserFormChange}
                name="email"
                fullWidth={true}
                isLabel={true}
                isValid={userFormData.isValidEmail}
                message={
                  userFormData.email.trim().length > 0
                    ? "Please enter an valid email address"
                    : "Please enter an email address"
                }
                label="Email"
                onBlur={handleBlur}
                value={userFormData.email}
                placeholder="Enter email here"
                height="20px"
                labelVariant="regular_14px"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ ml: -1, pointerEvents: "none" }}
                    >
                      {userFormData.email.trim() === "" ? (
                        <img src={grey_email} alt="icon" />
                      ) : (
                        <img src={blue_email} alt="icon" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                onChange={handleUserFormChange}
                name="company_name"
                fullWidth={true}
                isLabel={true}
                isValid={userFormData.isValidCompanyName}
                label="Company Name"
                onBlur={handleBlur}
                value={userFormData.company_name}
                placeholder="Enter name here"
                height="20px"
                labelVariant="regular_14px"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ my: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ height: "30px", width: "fit-content", m: 0 }}
            onClick={() => {
              clearUserFormData();
              handleClose();
            }}
          >
            Cancel
          </Button>
          <SubmitButton
            loading={userFormLoader}
            onClick={handleUserFormSubmit}
            variant="contained"
            style={{ height: "30px", width: "fit-content", m: 0 }}
            disabled={buttonDisabled}
            text={isEditable ? "Update" : "Add"}
          />
        </DialogActions>
      </DialogBox>
    </div>
  );
};

export default AddSalesPerson;
