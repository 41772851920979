import { createTheme } from "@mui/material";

export const sosColors = {
  primary: "#6EC1E4",
  secondary: "#54595F",
  text: "#7A7A7A",
  headingText: "#383838",
  dark: "#000000",
  white: "#FFFFFF",
  background: "#FEF8F9",
  disableBG: "#E4E4E4",
  ascent1: "#61CE70",
  ascent2: "#176FA1",
  divider: "#F2F4F8",
  toastMsgTxt: "#178C27",
  pageBackground: "#F3F8F9",
  placeholder: "#AFAFAF",
  tableBackground: "#E2F6E5",
};

export const sosStatusColors = {
  1: "#1908DB",
  2: "#08A8DB",
  3: "#FFC700",
  4: "#FB0606",
  5: "#FF0BC9",
  6: "#97A401",
  7: "#41C153",
  8: "#33FFBB",
} as any;

export const themePalette = createTheme({
  palette: {
    primary: {
      main: sosColors.primary,
    },
    secondary: {
      main: sosColors.secondary,
      light: "#E1E0DF",
      grayPrimary: "#cccc",
      blackSecondary: "242221",
      blackText: "rgba(36, 34, 33, 1)",
      dark: "#4B4B4B",
      grey: "#F7F7F7",
      greySecondary: "#1E1C1B",
      bodyBackground: "#FCFBFA",
      borderBlack: "#242221",
      divider: "#EFEFEF",
      black: "#000000",
      white: "#FFFFFF",
      transparent: "rgba(75, 75, 75, 0.16)",
      danger: "#BD3D21",
      blueChalk: "#F2DAFF",
      borderLight: "#E9DFEB",
      outlineLight: "#7C757F29",
      outlineXLight: "#C4C4C4",
      textFieldBorder: "#CFD0D7",
      primaryGradient: "linear-gradient(45deg, #CF6729 0%, #E6B090 100%)",
      secondaryGradient: "linear-gradient(45deg, #82868B 0%, #9CA0A4 100%)",
      successGradient: "linear-gradient(45deg, #28C76F 0%, #48DA89 100%)",
      dangerGradient: "linear-gradient(45deg, #CF2F30 0%, #F57273 100%)",
      warningGradient: "linear-gradient(45deg, #FF9F43 0%, #FFB976 100%)",
      infoGradient: "linear-gradient(45deg, #00CFE8 0%, #1CE7FF 100%)",
      backgroundGradient: "#f5f2f7",
      progressGradient1: "#FFD141",
      progressGradient2: "#E97300",
      primaryContainerLight: "#F2DAFF",
      lightOnPrimaryContainer: "#2C0051",
      radioShadow: "rgba(16,22,26,.2)",
      lightDivider: "#4B465C05",
    } as any,
    error: {
      main: "#BA1B1B",
    },
    focus: {
      main: "#d1eaff",
    },
    warning: {
      main: "#ed6c02",
    },
    success: {
      main: "#00CE7D",
    },
    hover: {
      main: "#40a9ff",
    },
    tabIndicator: { main: "#F32836" },
    button: {
      main: "#E0B7FF",
      dark: "#45166E",
      borderLight: "#968E99",
      light: "#E7E1E5",
      link: "#0047BB",
      lightSecondary: "#EDDDF6",
    },
    containerBorder: {
      light: "#968E99",
    },
    containers: {
      surface5: "rgba(118, 73, 160, 0.14)",
    },
  } as any,
});
