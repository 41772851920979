import styled from "@emotion/styled";
import { Autocomplete, Popover } from "@mui/material";
import { sosColors } from "../../constants/theme/colors";

export const StyledAutoComplete = styled(Autocomplete)(({ theme }) => ({
  "&.MuiFormControl-root": {
    height: "30px",
  },
  "& .MuiInputBase-root": {
    padding: 0,
  },
  "& .MuiInputBase-input": {
    padding: "6px",
  },
  "& .MuiAutocomplete-popper": {
    maxHeight: "100px",
  },
}));

export const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    scrollbarColor: sosColors["primary"],
    scrollbarWidth: "thin",
    overflowX: "hidden",
    maxHeight: "200px",
  },
  "& .MuiPaper-root::-webkit-scrollbar": {
    width: "2px !important",
  },
  "& .MuiPaper-root::-webkit-scrollbar-track": {
    background: sosColors["primary"],
  },
  "& .MuiPaper-root::-webkit-scrollbar-thumb": {
    background: "#888 !important",
  },
}));
