import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { sosColors } from "../../constants/theme/colors";

export const ContentBox = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    boxShadow: "0px 10px 20px 0px #0000001F",
    borderRadius: "5px",
  },
}));

export const BlueBox = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    backgroundColor: sosColors["primary"],
    padding: "15px 25px",
    display: "flex",
    alignItems: "center",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
}));

export const WhiteBox = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    padding: "20px 25px",
    backgroundColor: sosColors["white"],
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

export const StatusTag = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    border: "1px solid",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "center",
    padding: "4px 8px",
    borderRadius: "4px",
  },
}));
