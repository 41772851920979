import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { firebaseConfig } from "../firebase-config";
import { initializeApp } from "firebase/app";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Create an instance of Axios
const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  maxRedirects: 0,
});

// Add request interceptor
axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig | any) => {
    const token = localStorage.getItem("asg_access_token");
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error: AxiosError) => {
    console.error("Request Error Interceptor:", error);
    return Promise.reject(error);
  }
);

// Add response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError | any) => {
    console.error("Response Error Interceptor:", error);
    if (
      (error.response && error.response.status === 401) ||
      (error.response && error.response.status === 403)
    ) {
      try {
        await refreshToken();
        // Retry original request after token refresh
        return axiosInstance(error.config);
      } catch (refreshError) {
        localStorage.clear();
        window.location.reload();
        console.error("Token refresh failed:", refreshError);
        throw error;
      }
    }
    throw error;
  }
);

// Function to refresh the token
async function refreshToken(): Promise<void> {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const refreshedToken = await getIdToken(user);
          localStorage.setItem("asg_access_token", refreshedToken);
          // resolve();
          window.location.reload();
        } catch (error) {
          localStorage.clear();
          window.location.reload();
          console.error("Error refreshing token:", error);
          reject(error);
        }
      } else {
        localStorage.clear();
        window.location.reload();
        reject(new Error("No user is currently signed in."));
      }
    });
  });
}

export default axiosInstance;
