import React from "react";
import DialogBox from "../DialogBox";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import { sosColors } from "../../constants/theme/colors";
import SubmitButton from "../SubmitButton";
import white_delete from "../../assets/icons/white_delete.svg";

interface Props {
  open: boolean;
  handleClose: Function;
  handleSubmit: Function;
  label: string;
  loader?: boolean | any;
}

const DeleteModal = (props: Props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {
          props.handleClose();
        }}
      >
        <DialogContent sx={{ p: "0px !important" }}>
          <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                backgroundColor: "red",
                py: 3,
                px: 3.5,
                width: "fit-content",
                borderRadius: "50px",
              }}
            >
              <img src={white_delete} alt="delete" />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
              <Typography
                variant="medium_16px"
                color={sosColors["headingText"]}
              >
                Delete {props.label}
              </Typography>
              <Typography variant="regular_13px" color={sosColors["secondary"]}>
                Are you sure you want to delete the {props.label}?
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ my: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ height: "30px", width: "fit-content", m: 0 }}
            onClick={() => {
              props.handleClose();
            }}
          >
            Cancel
          </Button>
          <SubmitButton
            loading={props.loader}
            onClick={props.handleSubmit}
            variant="contained"
            style={{ height: "30px", width: "fit-content", m: 0 }}
            text={"Delete"}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteModal;
