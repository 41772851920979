import React, { useEffect, useState } from "react";
import DialogBox from "../DialogBox";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
  InputAdornment,
  Menu,
  MenuItem,
  Popover,
} from "@mui/material";
import SubmitButton from "../SubmitButton";
import { sosColors } from "../../constants/theme/colors";
import { ScrollBox } from "../../common/style";
import DropDown from "../DropDown";
import {
  addLead,
  getAllLeads,
  getAllUsers,
  getProjectTypeList,
  updateLead,
} from "../../services";
import { SmallInputField } from "../../constants/InputField/input";
import {
  validateContact,
  validateEmail,
  validateName,
  validateOnlyDigits,
  validatePinCode,
  validateValue,
} from "../../utils/validation";
import { toast } from "react-toastify";
import { saveUsers } from "../../store/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useParams } from "react-router-dom";
import usa_json from "../../assets/usa_state_cities.json";
import grey_email from "../../assets/icons/grey_email.svg";
import grey_phone from "../../assets/icons/grey_phone.svg";
import blue_email from "../../assets/icons/blue_email.svg";
import blue_phone from "../../assets/icons/blue_phone.svg";
import {
  DocumentData,
  getDocs,
  query,
  orderBy,
  collection,
  where,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import LeadDetails from "../../pages/LeadDetails";
import { StyledPopover } from "./style";
interface Props {
  open: boolean;
  handleClose: Function;
  data?: any;
  editMode?: boolean;
  fetchData?: Function | any;
}

function AddLead(props: Props) {
  const dispatch = useDispatch();
  const params = useParams();
  var docRef: any = collection(db, `leads`);
  const reduxState: any = useSelector((state) => state);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [projectTypeFilter, setProjectTypeFilter] = useState<any>({});
  const [salesPerson, setSalesPerson] = useState<any>({});
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [projectList, setProjectList] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [leadList, setLeadList] = useState<any>([]);
  const [filteredLeadList, setFilteredLeadList] = useState<any>([]);
  const [formData, setFormData] = useState<any>({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    address_line_1: "",
    address_line_2: "",
    locality: "",
    street: "",
    city: "",
    state: "",
    status: "New",
    postal_code: "",
    country: "USA",
    measurements: "",
    bid_value: "",
    comment: "",
    company_name: "",
    project_name: "",
  });

  const [formError, setFormError] = useState({
    isValidFirst_name: true,
    isValidLast_name: true,
    isValidEmail: true,
    isValidPhone: true,
    isValidAddress_line_1: true,
    isValidAddress_line_2: true,
    isValidLocality: true,
    isValidStreet: true,
    isValidCity: true,
    isValidState: true,
    isValidCountry: true,
    isValidPostal_code: true,
    isValidProject_type: true,
    isValidMeasurements: true,
    isValidBid_value: true,
    isValidSales_person: true,
    isValidComment: true,
    isValidCompanyName: true,
    isValidProjectName: true,
  });

  useEffect(() => {
    if (leadList.length === 0) {
      fetchUserList();
    }
  }, [props.open,formData.company_name]);

  const fetchUserList = async () => {
    let dropdata: any[] = [];

    try {
      let q = query(
        docRef,
        where("company_name", ">=", formData.company_name),
        orderBy("company_name")
      );
      // Fetch documents from docRef query
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc: any) => {
        const data = doc.data() as DocumentData;
        dropdata.push(data);
      });
      setLeadList(dropdata);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLeadSelect = (lead: any) => {
    setFormData({
      first_name: lead.first_name,
      last_name: lead.last_name,
      phone: lead.phone,
      email: lead.email,
      address_line_1: lead.address_line_1,
      address_line_2: "",
      locality: lead.locality,
      street: lead.street,
      city: lead.city,
      state: lead.state,
      status: "New",
      postal_code: lead.postal_code,
      country: "USA",
      measurements: "",
      bid_value: "",
      comment: "",
      company_name: lead.company_name,
      project_name: "",
    });
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.open) {
      getProjectTypeList().then((response) => {
        setProjectList(response?.data.types);
      });
      getAllUsers()
        .then((response: any) => {
          if (response.status === 200 && response.data.status_code === 200) {
            dispatch(saveUsers(response.data.users));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.open]);

  useEffect(() => {
    if (props.data !== undefined && !_.isEmpty(props.data) && props.editMode) {
      setProjectTypeFilter(props.data.project_type);
      let editData: any = { ...props.data };
      setSalesPerson({
        name: `${props.data.sales_person.first_name} ${props.data.sales_person.last_name}`,
        id: props.data.sales_person.id,
      });
      editData.first_name = _.capitalize(props.data.first_name);
      editData.last_name = _.capitalize(props.data.last_name);
      setFormData(editData);
    }
  }, [props.data, props.open]);

  useEffect(() => {
    if (
      formData.first_name !== "" &&
      formData.last_name !== "" &&
      formData.phone !== "" &&
      formData.email !== "" &&
      formData.address_line_1 !== "" &&
      formData.street !== "" &&
      formData.city !== "" &&
      formData.state !== "" &&
      formData.postal_code !== "" &&
      formData.measurements !== "" &&
      formData.bid_value !== "" &&
      formError.isValidFirst_name &&
      formError.isValidLast_name &&
      formError.isValidPhone &&
      formError.isValidEmail &&
      formError.isValidAddress_line_1 &&
      formError.isValidAddress_line_2 &&
      formError.isValidLocality &&
      formError.isValidStreet &&
      formError.isValidCity &&
      formError.isValidState &&
      formError.isValidCountry &&
      formError.isValidPostal_code &&
      formError.isValidMeasurements &&
      formError.isValidBid_value &&
      formError.isValidComment &&
      formError.isValidCompanyName &&
      formError.isValidProjectName &&
      !_.isEmpty(projectTypeFilter) &&
      !_.isEmpty(salesPerson)
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [formData, formError, projectTypeFilter, salesPerson]);

  const handleBlur = (e: any) => {
    let errors: any = { ...formError };
    switch (e.target.name) {
      case "first_name": {
        errors.isValidFirst_name = validateName(e.target.value);
        break;
      }
      case "last_name": {
        errors.isValidLast_name = validateName(e.target.value);
        break;
      }
      case "email": {
        errors.isValidEmail = validateEmail(e.target.value);
        break;
      }
      case "phone": {
        errors.isValidPhone = validateContact(e.target.value);
        break;
      }
      case "address_line_1": {
        errors.isValidAddress_line_1 =
          validateValue(e.target.value) && e.target.value.length <= 50;
        break;
      }
      case "address_line_2": {
        errors.isValidAddress_line_2 = e.target.value.length <= 50;
        break;
      }
      case "locality": {
        errors.isValidLocality =
          validateValue(e.target.value) && e.target.value.length <= 50;
        break;
      }
      case "city": {
        errors.isValidCity =
          validateValue(e.target.value) && e.target.value.length <= 20;
        break;
      }

      case "state": {
        errors.isValidState =
          validateValue(e.target.value) && e.target.value.length <= 20;
        break;
      }
      case "country": {
        errors.isValidCountry = validateValue(e.target.value);
        break;
      }
      case "street": {
        errors.isValidStreet =
          validateValue(e.target.value) && e.target.value.length <= 50;
        break;
      }
      case "postal_code": {
        errors.isValidPostal_code =
          e.target.value.length >= 0 &&
          e.target.value.length <= 8 &&
          validateOnlyDigits(e.target.value);
        break;
      }
      case "measurements": {
        errors.isValidMeasurements =
          validateOnlyDigits(e.target.value) &&
          e.target.value.length > 0 &&
          e.target.value.length <= 10;
        break;
      }
      case "bid_value": {
        errors.isValidBid_value =
          validateOnlyDigits(e.target.value) &&
          e.target.value.length > 0 &&
          e.target.value.length <= 10;
        break;
      }
      case "comment": {
        errors.isValidComment = e.target.value.length <= 5000;
        break;
      }
      case "company_name": {
        errors.isValidCompanyName = e.target.value.length <= 50;
        break;
      }
      case "project_name": {
        errors.isValidProjectName = e.target.value.length <= 50;
        break;
      }
      default: {
        break;
      }
    }
    setFormError(errors);
  };

  const handleChange = (e: any) => {
    let data: any = { ...formData };
    let list: any = [];
    if (e.target.name === "company_name") {
      const filteredByEmail = leadList.filter((lead: any) =>
        lead.company_name
          .toLowerCase()
          .includes(e.target.value.trim().toLowerCase())
      );

      const uniqueEmails = new Set();
      const filteredData: any = [];

      filteredByEmail.forEach((item: any) => {
        const email = item.email.trim().toLowerCase();
        if (!uniqueEmails.has(email)) {
          uniqueEmails.add(email);
          filteredData.push(item);
        }
      });

      setFilteredLeadList(filteredData);
      setAnchorEl(e.currentTarget);
    }
    data[e.target.name] = e.target.value;
    setFormData(data);

    // validateForm();
  };

  const handleSelectChange = (value: any, name: string) => {
    console.log(value, name);
    let data: any = { ...formData };
    data[name] = value;
    setFormData(data);
    // validateForm();
  };

  const clearData = () => {
    setFormData({
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      address_line_1: "",
      address_line_2: "",
      locality: "",
      street: "",
      city: "",
      state: "",
      status: "New",
      postal_code: "",
      country: "USA",
      measurements: "",
      bid_value: "",
      comment: "",
      company_name: "",
      project_name: "",
    });
    setFormError({
      isValidFirst_name: true,
      isValidLast_name: true,
      isValidEmail: true,
      isValidPhone: true,
      isValidAddress_line_1: true,
      isValidAddress_line_2: true,
      isValidLocality: true,
      isValidStreet: true,
      isValidCity: true,
      isValidState: true,
      isValidPostal_code: true,
      isValidProject_type: true,
      isValidMeasurements: true,
      isValidBid_value: true,
      isValidCountry: true,
      isValidComment: true,
      isValidSales_person: true,
      isValidCompanyName: true,
      isValidProjectName: true,
    });
    setProjectTypeFilter({});
    setSalesPerson({});
  };

  const handleSubmit = () => {
    setLoader(true);
    let payload: any = { ...formData };
    payload.project_type = projectTypeFilter.id;
    payload.sales_person = salesPerson.id;
    payload.measurements = parseInt(formData.measurements);
    payload.bid_value = parseInt(formData.bid_value);
    payload.first_name = formData.first_name.toLowerCase().trim();
    payload.last_name = formData.last_name.toLowerCase().trim();
    payload.email = formData.email.trim();
    payload.project_name =
      payload.project_name.trim() === "" ? "NA" : payload.project_name;

    if (!props.editMode) {
      addLead(payload)
        .then((response: any) => {
          if (response.status === 201 && response.data.status_code === 201) {
            setLoader(false);
            props.handleClose();
            clearData();
            props.fetchData();
            toast.success("Lead added successfully!");
          } else {
            setLoader(false);
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
          toast.error("Something went wrong!");
        });
    } else {
      payload.status = props.data.status.name;
      updateLead({ id: params?.id, payload })
        .then((response: any) => {
          if (response.status === 201 && response.data.status_code === 201) {
            setLoader(false);
            props.handleClose();
            clearData();
            props.fetchData();

            toast.success("Lead updated successfully!");
          } else {
            setLoader(false);
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
          toast.error("Something went wrong!");
        });
    }
  };



  
  return (
    <div>
      <DialogBox
        title={props.editMode ? "Edit Lead" : "Add New Lead"}
        open={props.open}
        handleClose={() => {
          clearData();
          props.handleClose();
        }}
      >
        <DialogContent sx={{ p: "0px !important" }}>
          <ScrollBox
            sx={{ py: 2, px: "16px !important", height: "calc(100vh - 270px)" }}
          >
            <Box sx={{ pb: 1 }}>
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={4} sx={{ position: "relative" }}>
                  <SmallInputField
                    isRequired={false}
                    name="company_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                    isLabel={true}
                    isValid={formError.isValidCompanyName}
                    label="Company Name"
                    placeholder="Enter name here"
                    height="30px"
                    labelVariant="regular_13px"
                    message={"Company name must be less then 50 characters"}
                    value={formData.company_name}
                  />
                  <StyledPopover
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={
                      Boolean(anchorEl) &&
                      formData.company_name.trim().length > 1 &&
                      filteredLeadList.length > 0
                    }
                    onClose={handleClose}
                    disableAutoFocus={true}
                    sx={{
                      width: "600px",
                      mt: 0.6,
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {filteredLeadList.map((lead: any) => {
                      return (
                        <MenuItem onClick={() => handleLeadSelect(lead)}>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="semibold_14px"
                              color={sosColors["headingText"]}
                            >
                              {lead.company_name}
                            </Typography>
                            <Typography
                              variant="regular_14px"
                              color={sosColors["text"]}
                            >
                              {lead.email}
                            </Typography>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </StyledPopover>
                </Grid>
                <Grid item xs={4}>
                  <SmallInputField
                    isRequired={true}
                    onBlur={handleBlur}
                    name="first_name"
                    isLabel={true}
                    isValid={formError.isValidFirst_name}
                    fullWidth={true}
                    onChange={handleChange}
                    label="First Name"
                    placeholder="Enter name here"
                    height="30px"
                    labelVariant="regular_13px"
                    message={
                      formData.first_name.length === 0
                        ? "Please enter first name"
                        : "Please enter a valid name"
                    }
                    value={formData.first_name}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SmallInputField
                    isRequired={true}
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                    isLabel={true}
                    isValid={formError.isValidLast_name}
                    label="Last Name"
                    placeholder="Enter name here"
                    height="30px"
                    labelVariant="regular_13px"
                    message={
                      formData.last_name.length === 0
                        ? "Please enter last name"
                        : "Please enter a valid name"
                    }
                    value={formData.last_name}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ pt: 1, pb: 2 }}>
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={6}>
                  <SmallInputField
                    sx={{ height: "30px" }}
                    isRequired={true}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isLabel={true}
                    isValid={formError.isValidEmail}
                    fullWidth={true}
                    label="Email"
                    placeholder="example@email.com"
                    height="30px"
                    labelVariant="regular_13px"
                    message={
                      formData.email.length === 0
                        ? "Please enter a email"
                        : "Please enter a valid email"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ ml: -1, pointerEvents: "none" }}
                        >
                          {formData.email.trim() === "" ? (
                            <img src={grey_email} alt="icon" />
                          ) : (
                            <img src={blue_email} alt="icon" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    value={formData.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SmallInputField
                    isRequired={true}
                    onBlur={handleBlur}
                    name="phone"
                    onChange={handleChange}
                    fullWidth={true}
                    isLabel={true}
                    isValid={formError.isValidPhone}
                    label="Phone Number"
                    placeholder="XXXXX XXXXX"
                    height="30px"
                    labelVariant="regular_13px"
                    message={
                      formData.phone.length === 0
                        ? "Please enter a phone number"
                        : "Please enter a valid phone number"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ ml: -1, pointerEvents: "none" }}
                        >
                          {formData.phone.trim() === "" ? (
                            <img src={grey_phone} alt="icon" />
                          ) : (
                            <img src={blue_phone} alt="icon" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    value={formData.phone}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box sx={{ pb: 1, pt: 2 }}>
              <Typography
                variant="medium_15px"
                color={sosColors["headingText"]}
              >
                Address details:
              </Typography>
            </Box>
            <Box sx={{ pt: 1, pb: 1 }}>
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={6}>
                  <SmallInputField
                    sx={{ height: "30px" }}
                    isRequired={true}
                    onBlur={handleBlur}
                    name="address_line_1"
                    onChange={handleChange}
                    isLabel={true}
                    isValid={formError.isValidAddress_line_1}
                    fullWidth={true}
                    label="Address"
                    placeholder="Enter Address Here"
                    height="30px"
                    labelVariant="regular_13px"
                    message={"Please enter a address"}
                    value={formData.address_line_1}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SmallInputField
                    onBlur={handleBlur}
                    isRequired={true}
                    name="street"
                    onChange={handleChange}
                    fullWidth={true}
                    isLabel={true}
                    isValid={formError.isValidStreet}
                    label="Street Name"
                    placeholder="Enter Address Here"
                    height="30px"
                    labelVariant="regular_13px"
                    message={"Please enter Street Name"}
                    value={formData.street}
                  />
                </Grid>
              </Grid>
            </Box>

            <Divider />
            <Box sx={{ pb: 2, pt: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "24%" }}>
                  <SmallInputField
                    isRequired={true}
                    onBlur={handleBlur}
                    sx={{ height: "30px" }}
                    name="city"
                    onChange={handleChange}
                    isLabel={true}
                    isValid={formError.isValidCity}
                    fullWidth={true}
                    label="City"
                    placeholder="Enter City"
                    height="30px"
                    labelVariant="regular_13px"
                    message={"Please enter a city"}
                    value={formData.city}
                  />
                </Box>
                <Box sx={{ width: "24%" }}>
                  <SmallInputField
                    isRequired={true}
                    onBlur={handleBlur}
                    sx={{ height: "30px" }}
                    name="state"
                    onChange={handleChange}
                    isLabel={true}
                    isValid={formError.isValidState}
                    fullWidth={true}
                    label="State"
                    placeholder="Enter State"
                    height="30px"
                    labelVariant="regular_13px"
                    message={"Please enter a state"}
                    value={formData.state}
                  />
                </Box>

                <Box sx={{ width: "24%" }}>
                  <SmallInputField
                    isRequired={true}
                    onBlur={handleBlur}
                    sx={{ height: "30px" }}
                    name="postal_code"
                    onChange={handleChange}
                    isLabel={true}
                    isValid={formError.isValidPostal_code}
                    fullWidth={true}
                    label="Zip Code"
                    placeholder="Type here"
                    height="30px"
                    labelVariant="regular_13px"
                    message={"Please enter a zip code"}
                    value={formData.postal_code}
                  />
                </Box>
                <Box sx={{ width: "24%" }}>
                  <SmallInputField
                    name="country"
                    onChange={handleChange}
                    fullWidth={true}
                    isLabel={true}
                    isValid={true}
                    label="Country"
                    value={formData.country}
                    height="30px"
                    labelVariant="regular_13px"
                  />
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ pt: 1 }}>
              <Box sx={{ pb: 2 }}>
                <Typography
                  variant="medium_15px"
                  color={sosColors["headingText"]}
                >
                  Project details:
                </Typography>
              </Box>

              <Box>
                <Grid container spacing={2} justifyContent={"center"}>
                  <Grid item xs={6}>
                    <Box sx={{ mb: 1 }}>
                      <Typography
                        variant="regular_13px"
                        color={sosColors["secondary"]}
                      >
                        Project Type{"   "}*
                      </Typography>
                    </Box>
                    <DropDown
                      placeholder="Select Project Type"
                      selectionList={projectList}
                      handleChange={(value: object) => {
                        setProjectTypeFilter(value);
                        // validateForm();
                      }}
                      value={projectTypeFilter}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SmallInputField
                      isRequired={false}
                      name="project_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth={true}
                      isLabel={true}
                      isValid={formError.isValidProjectName}
                      label="Project Name"
                      placeholder="Enter name here"
                      height="30px"
                      labelVariant="regular_13px"
                      message={"Project name must be less then 50 characters"}
                      value={formData.project_name}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ pt: 2 }}>
                <Grid container spacing={2} justifyContent={"center"}>
                  <Grid item xs={6}>
                    <SmallInputField
                      onBlur={handleBlur}
                      name="measurements"
                      onChange={handleChange}
                      isRequired={true}
                      isLabel={true}
                      isValid={formError.isValidMeasurements}
                      fullWidth={true}
                      label="Total sq.ft"
                      placeholder="Enter here"
                      height="30px"
                      labelVariant="regular_13px"
                      message={"Please enter a valid input"}
                      value={formData.measurements}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SmallInputField
                      name="bid_value"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isRequired={true}
                      fullWidth={true}
                      isLabel={true}
                      isValid={formError.isValidBid_value}
                      label="Project bid amount ($)"
                      placeholder="Enter here"
                      height="30px"
                      labelVariant="regular_13px"
                      message={"Please enter a valid input"}
                      value={formData.bid_value}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ pt: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      variant="regular_13px"
                      color={sosColors["secondary"]}
                    >
                      Assign sales person{"   "}*
                    </Typography>
                  </Box>
                  <DropDown
                    placeholder="Select Project Type"
                    selectionList={reduxState?.userData?.users?.map(
                      (user: any) => {
                        return {
                          id: user.id,
                          name: `${user.first_name} ${user.last_name}`,
                        };
                      }
                    )}
                    handleChange={(value: object) => {
                      setSalesPerson(value);
                      // validateForm();
                    }}
                    value={salesPerson}
                  />
                </Box>
                <Box sx={{ pt: 2 }}>
                  <SmallInputField
                    name="comment"
                    fullWidth={true}
                    onChange={handleChange}
                    isLabel={true}
                    isValid={formError.isValidComment}
                    label="Additional Notes"
                    placeholder="Type here"
                    labelVariant="regular_13px"
                    rows={5}
                    multiline={true}
                    message="Character limit for note is 5000"
                    onBlur={handleBlur}
                    value={formData.comment}
                  />
                </Box>
              </Box>
            </Box>
          </ScrollBox>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ my: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ height: "30px", width: "fit-content", m: 0 }}
            onClick={() => {
              clearData();
              props.handleClose();
            }}
          >
            Cancel
          </Button>
          <SubmitButton
            disabled={disableButton}
            loading={loader}
            onClick={handleSubmit}
            variant="contained"
            style={{ height: "30px", width: "fit-content", m: 0 }}
            text={props.editMode ? "Update Lead" : "Add Lead"}
          />
        </DialogActions>
      </DialogBox>
    </div>
  );
}

export default AddLead;
