import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import cross from "../../assets/icons/cross.svg";
import { Box, Typography } from "@mui/material";
import { sosColors } from "../../constants/theme/colors";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    maxWidth: "690px !important",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface Props {
  open: boolean;
  handleClose: any;
  children?: any;
  title: string;
}

const DialogBox = (props: Props) => {
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle
          component={"div"}
          sx={{
            m: 0,
            py: 2,
            px: 2,
            width: "656px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: sosColors["divider"],
          }}
          id="customized-dialog-title"
        >
          <Typography color={sosColors["ascent2"]} variant="medium_15px">
            {props.title}
          </Typography>
          <IconButton aria-label="close" onClick={props.handleClose}>
            <img src={cross} alt="close" />
          </IconButton>
        </DialogTitle>
        <Box>{props.children}</Box>
      </BootstrapDialog>
    </React.Fragment>
  );
};
export default DialogBox;
