export const csvStructure = [
  {
    label: "First Name",
    key: "first_name",
  },
  {
    label: "Last Name",
    key: "last_name",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Phone",
    key: "phone",
  },

  {
    label: "Address",
    key: "address_line_1",
  },
  {
    label: "Street",
    key: "street",
  },
  {
    label: "city",
    key: "city",
  },
  {
    label: "state",
    key: "state",
  },
  {
    label: "Zip Code",
    key: "postal_code",
  },

  {
    label: "Project Type",
    key: "project_type",
  },
  {
    label: "Total Squarefeet",
    key: "measurements",
  },
  {
    label: "Bid Amount",
    key: "bid_value",
  },
  {
    label: "Status",
    key: "status",
  },
  {
    label: "Date Added",
    key: "created_at",
  },
  {
    label: "Last Updated",
    key: "updated_at",
  },
  {
    label: "Assigned to",
    key: "sales_person",
  },
  {
    label: "Notes",
    key: "allcoments",
  },
];
