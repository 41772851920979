import styled from "@emotion/styled";
import { TimePicker } from "@mui/x-date-pickers";

export const StyledTimePicker = styled(TimePicker)(({ theme }) => ({
  "&.MuiFormControl-root": {
    height: "26px",
    minWidth: "100%",
  },
  "&.MuiStack-root": {
    paddingTop: 0,
  },
  "& .MuiInputBase-root": {
    height: "26px",
    padding: "5px 8px",
    flexDirection: "row-reverse",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  "& .MuiInputBase-input": {
    textTransform: "uppercase",
  },
  "& .MuiInputAdornment-root": {
    marginLeft: "0px",
  },
  "& .MuiButtonBase-root": {
    padding: "0px",
    paddingRight: "5px",
  },
  "& .MuiSvgIcon-root": {
    height: "16px",
  },
}));
