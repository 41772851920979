import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AuthenticationLayout from "../../layout/AuthenticationLayout";
import {
  InputField,
  InputPasswordField,
} from "../../constants/InputField/input";
import { sosColors } from "../../constants/theme/colors";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../utils/routes";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../firebase-config";
import {
  confirmPasswordReset,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import SubmitButton from "../../components/SubmitButton";
import { toast } from "react-toastify";
import {
  validateBlankSpace,
  validateEmail,
  validateValue,
} from "../../utils/validation";

export interface state {
  confirmPassword: string;
  password: string;
  isValidPassword: boolean;
  isValidConfirmPassword: boolean;
}

const ResetPassword = () => {
  const url = window.location.search.split("&");
  const navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const auth: any = getAuth(app);
  const [formData, setFormData] = useState<state>({
    confirmPassword: "",
    password: "",
    isValidPassword: true,
    isValidConfirmPassword: true,
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, [document.body]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let FormData: any = { ...formData };
    FormData[e.target.name] = e.target.value;
    setFormData(FormData);
  };

  const handleSubmit = () => {
    let code = url[1].split("=");
    setLoader(true);
    if (formData.password === formData.confirmPassword) {
      confirmPasswordReset(auth, code[1], formData.password)
        .then(() => {
          setLoader(false);
          navigate(ROUTE_PATH.login);
          toast.success("Password has been reset successfully!");
        })
        .catch((error) => {
          console.log(error);
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(errorMessage);
          setLoader(false);
        });
    } else {
      toast.error("Both password should match");
    }
  };

  const handleOnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let FormData: any = { ...formData };
    if (e.target.name === "confirmPassword") {
      FormData.isValidConfirmPassword = e.target.value === FormData.password;
    } else {
      FormData.isValidPassword =
        e.target.value.length >= 8 && validateBlankSpace(e.target.value);
    }
    setFormData(FormData);
  };

  return (
    <AuthenticationLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "75%" }}>
          <InputPasswordField
            onBlur={handleBlur}
            onChange={handleChange}
            name={"password"}
            fullWidth={true}
            isLabel={true}
            value={formData.password}
            label="New Password"
            placeholder="Enter password"
            isValid={formData.isValidPassword}
            onKeyPress={handleOnKeyDown}
            message={
              "Your Password must contain atleast 8 characters and must not exceed 15 characters without spaces"
            }
          />
        </Box>
        <Box sx={{ width: "75%", my: "30px" }}>
          <InputPasswordField
            onBlur={handleBlur}
            onChange={handleChange}
            name={"confirmPassword"}
            fullWidth={true}
            isLabel={true}
            label="Confirm Password"
            value={formData.confirmPassword}
            placeholder="Enter password"
            isValid={formData.isValidConfirmPassword}
            onKeyPress={handleOnKeyDown}
            message={
              formData.confirmPassword !== formData.password
                ? "Password is not same as above"
                : "Please enter an password of minimum 8 characters"
            }
          />
        </Box>
        <Box>
          <Typography
            color={sosColors["ascent2"]}
            variant="regular_16px"
            sx={{
              ":hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
            onClick={() => navigate(ROUTE_PATH.forgot_password)}
          >
            Forgot Password?
          </Typography>
        </Box>
        <Box sx={{ width: "75%", mt: "30px" }}>
          <SubmitButton
            disabled={
              !(
                formData.password === formData.confirmPassword &&
                formData.password !== "" &&
                formData.confirmPassword !== "" &&
                formData.isValidPassword &&
                formData.isValidConfirmPassword
              )
            }
            variant="contained"
            onClick={() => handleSubmit()}
            style={{ height: "51px" }}
            loading={loader}
            text="Reset Password"
          />
        </Box>
      </Box>
    </AuthenticationLayout>
  );
};

export default ResetPassword;
