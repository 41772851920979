import styled from "@emotion/styled";
import Select from "@mui/material/Select";
import { sosColors } from "../../constants/theme/colors";

export const StyledDropdown = styled(Select)(({ theme }) => ({
  "&.MuiInputBase-root": {
    height: "30px",
    border: `1px solid #E4E4E4`,
    padding: "8px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  "& .MuiSelect-select": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0em",
    textAlign: "left",
    color: sosColors["ascent2"],
    paddingLeft: "0px",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiSelect-nativeInput": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0em",
    textAlign: "left",
    color: sosColors["text"],
  },
}));
