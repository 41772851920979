import { Box, InputAdornment, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { sosColors } from "../../constants/theme/colors";
import { SmallInputField } from "../../constants/InputField/input";
import sqft_icon from "../../assets/icons/sqft_icon.svg";

interface Props {
  values: any;
  handleChange: Function;
}

const SqRangePicker = (props: Props) => {
  const [state, setState] = useState({
    max: "",
    min: "",
  });

  // useEffect(() => {
  //   props.handleChange(state);
  // }, [state]);

  useEffect(() => {
    setState(props.values);
  }, [props.values]);

  const handleChange = (e: any) => {
    e.preventDefault();
    let vals: any = { ...state };

    vals[e.target.name] = e.target.value;
    // setState(vals);
    props.handleChange(vals);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "35%" }}>
          <SmallInputField
            onChange={handleChange}
            name="min"
            value={state.min}
            placeholder="Minimum"
            height="28px"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={sqft_icon} alt="icon" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Typography
          variant="regular_12px"
          sx={{ mx: 1, mb: "20px" }}
          color={sosColors["headingText"]}
        >
          To
        </Typography>
        <Box sx={{ width: "35%" }}>
          <SmallInputField
            onChange={handleChange}
            name="max"
            value={state.max}
            placeholder="Maximum"
            height="28px"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={sqft_icon} alt="icon" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default SqRangePicker;
