import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
interface State {
  users: any;
}

// Define the initial state using that type
const initialState: State = {
  users: [],
};

export const userSlice = createSlice({
  name: "users",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: initialState,
  reducers: {
    saveUsers: (state, action: PayloadAction<any>) => {
      state.users = action.payload;
    },
  },
});

export const { saveUsers } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const usersList = (state: RootState) => state.userData;

export default userSlice.reducer;
