import React from "react";
import "./style.css";
import { sosColors } from "../../constants/theme/colors";
import { CircularProgress } from "@mui/material";

interface Props {
  isLoading: boolean;
}

const OnScreenLoader = (props: Props) => {
  return props.isLoading ? (
    <div id="fixed" className="onscreen-loader-container">
      <div className="onscreen-loader">
        <CircularProgress
          color={"primary"}
          sx={{ width: "80px", height: "80px" }}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default OnScreenLoader;
