import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { sosColors } from "../../constants/theme/colors";
import { DatePickerComponent } from "./style";
import date_picker_icon from "../../assets/icons/date_picker_icon.svg";

export interface Props {
  date?: any;
  getDate?: Function | any;
}

function DateRangePicker(props: Props) {
  const [storedDate, setStoredDate] = useState({
    startDate: null,
    endDate: null,
  });
  const DatePickerIcon = () => {
    return <img src={date_picker_icon} alt="date_picker" />;
  };

  // useEffect(() => {
  //   props.getDate(storedDate);
  // }, [storedDate]);

  useEffect(() => {
    setStoredDate(props.date);
  }, [props.date]);

  const handleChange = (e: any, name: string) => {
    let Date: any = { ...storedDate };
    Date[name] = e;
    // setStoredDate(Date);
    props.getDate(Date);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <DatePickerComponent
          value={storedDate.startDate}
          slots={{
            openPickerIcon: DatePickerIcon,
          }}
          onChange={(e) => handleChange(e, "startDate")}
        />
        <Typography variant="regular_12px" color={sosColors["headingText"]}>
          To
        </Typography>
        <DatePickerComponent
          value={storedDate.endDate}
          slots={{
            openPickerIcon: DatePickerIcon,
          }}
          onChange={(e) => handleChange(e, "endDate")}
        />
      </Box>
    </div>
  );
}

export default DateRangePicker;
