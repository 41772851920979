import styled from "@emotion/styled";
import { DatePicker } from "@mui/x-date-pickers";

export const DatePickerComponent = styled(DatePicker)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "26px",
    padding: "5px 8px",
    flexDirection: "row-reverse",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  "&.MuiFormControl-root": {
    width: "45%",
  },
  "& .MuiInputAdornment-root": {
    marginLeft: "0px",
  },
  "& .MuiButtonBase-root": {
    padding: "0px",
    paddingRight: "5px",
  },
}));
