import { toast } from "react-toastify";

export function createFilters(searchData) {
  const filtersToApply = [];

  // Measurement Range Filter
  if (searchData.minSq && searchData.maxSq) {
    if (
      searchData.minSq >= 0 &&
      searchData.maxSq > 0 &&
      searchData.minSq < searchData.maxSq
    ) {
      filtersToApply.push(
        (item) =>
          item.measurements >= searchData.minSq &&
          item.measurements <= searchData.maxSq
      );
    } else {
      toast.error("Please enter a valid Sq ft range");
    }
  }

  // Sales Person Filter
  if (searchData.sales_person) {
    filtersToApply.push(
      (item) => item.sales_person.id === searchData.sales_person
    );
  }

  // Project Type Filter
  if (searchData.projectType) {
    filtersToApply.push(
      (item) => item.project_type.id === searchData.projectType
    );
  }

  // Status Filter
  if (searchData.statusReq && searchData.statusReq.length > 0) {
    filtersToApply.push((item) =>
      searchData.statusReq.includes(item.status.name)
    );
  }

  // Date Range Filter
  if (searchData.startDate && searchData.endDate) {
    const startDate = new Date(searchData.startDate);
    const endDate = new Date(searchData.endDate).setHours(24);

    if (startDate <= endDate) {
      filtersToApply.push((item) => {
        return (
          new Date(item.updated_at) >= startDate &&
          new Date(item.updated_at) <= endDate
        );
      });
    } else {
      toast.error("Please enter a valid date range");
    }
  }

  // Search Filter
if (searchData.search) {
  const searchTerm = searchData.search.toLowerCase().trim();

  filtersToApply.push(
    (item) =>
      item &&
      ["first_name", "last_name", "email", "project_name", "company_name"].some(
        (key) => item[key] && item[key].toLowerCase().includes(searchTerm)
      )
  );
}

  return filtersToApply;
}

export const getDuplicatesById = (array) => {
  const seen = new Set();
  const duplicates = [];

  array.forEach((item) => {
    if (seen.has(item.id)) {
      duplicates.push(item);
    } else {
      seen.add(item.id);
    }
  });

  return duplicates.length > 0 ? duplicates : array;
};

