import axios from "axios";
import {
  addLeadUrl,
  addNotesUrl,
  addUserUrl,
  deleteNoteUrl,
  deleteReminderUrl,
  deleteUserUrl,
  getAllLeadsCountUrl,
  getAllLeadsUrl,
  getAllUnPaginatedLeadsUrl,
  getAllUsersUrl,
  getLeadNotesUrl,
  getLeadRemindersUrl,
  getProjectTypeListUrl,
  getSingleLeadUrl,
  getStatusListUrl,
  saveReminderUrl,
  updateLeadSalesPersonUrl,
  updateLeadUrl,
  updateStatusUrl,
  updateUserUrl,
} from "../utils/urls";
import { toast } from "react-toastify";
import axiosInstance from "./axios";

export const getAllUsers = async () => {
  const response = await axiosInstance.get(getAllUsersUrl).catch((error) => {
    toast.error(error?.response?.data?.error);
    console.log(error);
  });
  return response;
};

export const addUser = async (payload) => {
  const response = await axiosInstance
    .post(addUserUrl, payload)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const deleteUser = async (payload) => {
  const response = await axiosInstance
    .delete(`${deleteUserUrl}?id=${payload.id}`)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const updateLeadSalesPerson = async (payload) => {
  const response = await axiosInstance
    .patch(
      `${updateLeadSalesPersonUrl}?ismulti=${payload.isMulti}&oldUser=${payload.oldUser}&newUser=${payload.newUser}&lead=${payload.lead}`
    )
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const updateUser = async (payload) => {
  const response = await axiosInstance
    .patch(`${updateUserUrl}?id=${payload.id}`, payload.payload)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const addLead = async (payload) => {
  const response = await axiosInstance
    .post(addLeadUrl, payload)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const updateLead = async (params) => {
  const response = await axiosInstance
    .patch(`${updateLeadUrl}?id=${params.id}`, params.payload)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const getSingleLead = async (payload) => {
  const response = await axiosInstance
    .get(`${getSingleLeadUrl}?id=${payload.id}`)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const getAllLeads = async (payload) => {
  const response = await axiosInstance
    .get(
      `${getAllLeadsUrl}?page=${payload.page}&project=${
        payload.type ?? ""
      }&minSq=${payload.minSq}&maxSq=${payload.maxSq}&search=${
        payload.search
      }&status=${payload.status}&startDate=${payload.startDate ?? ""}&endDate=${
        payload.endDate ?? ""
      }&sales_person=${payload.sales_person ?? ""}`
    )
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const getAllLeadsCount = async (payload) => {
  const response = await axiosInstance
    .get(`${getAllLeadsCountUrl}`)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const getAllUnPaginatedLeads = async (payload) => {
  const response = await axiosInstance
    .get(
      `${getAllUnPaginatedLeadsUrl}?&project=${payload.type ?? ""}&minSq=${
        payload.minSq
      }&maxSq=${payload.maxSq}&search=${payload.search}&status=${
        payload.status
      }&startDate=${payload.startDate ?? ""}&endDate=${
        payload.endDate ?? ""
      }&sales_person=${payload.sales_person ?? ""}`
    )
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const getAllStatus = async (payload) => {
  const response = await axiosInstance
    .get(`${getStatusListUrl}`)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const updateStatus = async (payload) => {
  const response = await axiosInstance
    .patch(`${updateStatusUrl}`, payload)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const addNotes = async (payload) => {
  const response = await axiosInstance
    .post(`${addNotesUrl}`, payload)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const getLeadNotes = async (payload) => {
  const response = await axiosInstance
    .get(`${getLeadNotesUrl}?id=${payload.id}`)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const deleteNote = async (payload) => {
  const response = await axiosInstance
    .delete(`${deleteNoteUrl}?id=${payload.id}`)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const getProjectTypeList = async (payload) => {
  const response = await axiosInstance
    .get(`${getProjectTypeListUrl}`)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const saveReminders = async (payload) => {
  const response = await axiosInstance
    .post(`${saveReminderUrl}`, payload)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const getLeadReminders = async (payload) => {
  const response = await axiosInstance
    .get(`${getLeadRemindersUrl}?id=${payload.id}`)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};

export const deleteReminder = async (payload) => {
  const response = await axiosInstance
    .delete(`${deleteReminderUrl}?id=${payload.id}`)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
      console.log(error);
    });
  return response;
};