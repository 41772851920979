import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import white_brand_logo from "../assets/logos/white_brand_logo.svg";
import logout from "../assets/icons/logout.svg";
import white_logout from "../assets/icons/white_logout.svg";
import plus from "../assets/icons/plus.svg";
import download from "../assets/icons/download.svg";
import { sosColors } from "../constants/theme/colors";
import { ROUTE_PATH } from "../utils/routes";
import { useNavigate, useParams } from "react-router-dom";
import { StyledNavTab } from "./style";
import "./style.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";
import SubmitButton from "../components/SubmitButton";
import { useAppDispatch } from "../store/hooks";
import AddLead from "../components/AddLead";
import { getAuth, signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../firebase-config";
import page_bg from "../assets/logos/image1.png";
import AddSalesPerson from "../components/AddSalesPerson";
import ChangePassword from "../components/ChangePassword";
import { generateCSV } from "../utils/generateCSV";

const pages = [
  { label: "Leads", route: [ROUTE_PATH.leads, ROUTE_PATH.lead] },
  { label: "Settings", route: [ROUTE_PATH.settings] },
];

const DashboardLayout = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const url = window.location.pathname;
  const [onClickUserForm, setOnClickUserForm] = React.useState<boolean>(false);
  const [onClickLeadForm, setOnClickLeadForm] = React.useState<boolean>(false);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [changePasswordModal, setChangePasswordModal] = React.useState(false);
  const [logoutModal, setLogoutModal] = React.useState(false);

  const handleLogout = async () => {
    signOut(auth)
      .then(() => {
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {
        console.log(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage);
      });
  };

  return (
    <Box>
      <AppBar
        position="sticky"
        sx={{
          height: "57px",
          backgroundColor: sosColors["ascent2"],
          px: "4%",
          mx: 0,
          justifyContent: "center",
        }}
      >
        <Container maxWidth="xl" sx={{ px: "0px !important" }}>
          <Toolbar disableGutters>
            <IconButton>
              <img src={white_brand_logo} alt="Atlantic sports group" />
            </IconButton>

            <Box sx={{ flexGrow: 1, mx: 2, display: "flex" }}>
              {pages.map((page) => (
                <StyledNavTab
                  className="nav-menu-tab"
                  key={page.label}
                  onClick={() => navigate(page.route[0])}
                  sx={{
                    mx: 2,
                    color: url.includes(page.route[0])
                      ? sosColors["white"]
                      : sosColors["primary"],
                    borderBottom: `4px solid ${
                      url.includes(page.route[0])
                        ? sosColors["ascent1"]
                        : "transparent"
                    }`,
                  }}
                >
                  <Typography variant="medium_14px">{page.label}</Typography>
                </StyledNavTab>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0, display: "flex" }}>
              {!url.includes("settings") ? (
                <>
                  <Button
                    variant="contained"
                    color={"secondary"}
                    sx={{
                      height: "34px",
                      width: "fit-content",
                    }}
                    onClick={() => setOnClickLeadForm(true)}
                  >
                    <img src={plus} alt="add" />
                    <Typography variant="regular_12px" sx={{ ml: 1 }}>
                      New Lead
                    </Typography>
                  </Button>
                  {!params.id && (
                    <Button
                      variant="contained"
                      color={"secondary"}
                      sx={{
                        height: "34px",
                        width: "34px",
                        p: 0,
                        ml: 2,
                      }}
                      onClick={() => props.downloadCsv()}
                    >
                      <img
                        src={download}
                        alt="download"
                        //   style={{ width: "18px", height: "18px" }}
                      />
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color={"secondary"}
                    sx={{
                      height: "34px",
                      width: "fit-content",
                    }}
                    onClick={() => setOnClickUserForm(true)}
                  >
                    <img src={plus} alt="add" />
                    <Typography variant="regular_14px" sx={{ ml: 1 }}>
                      Sales Person
                    </Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    color={"secondary"}
                    sx={{
                      height: "34px",
                      width: "fit-content",
                      px: 1,
                      ml: 2,
                    }}
                    onClick={() => setChangePasswordModal(true)}
                  >
                    Change Password
                  </Button>

                  <Button
                    variant="outlined"
                    color={"secondary"}
                    sx={{
                      height: "34px",
                      width: "34px",
                      p: 0,
                      ml: 2,
                    }}
                    onClick={() => setLogoutModal(true)}
                  >
                    <img
                      src={logout}
                      alt="download"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </Button>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          mx: "4%",
          my: "25px",
        }}
        id="dashboardLayoutContainer"
      >
        <img
          src={page_bg}
          alt="logo"
          style={{ position: "absolute", zIndex: -10, left: "35%" }}
        />
        {props.children}
      </Box>
      <AddSalesPerson
        open={onClickUserForm}
        handleClose={() => setOnClickUserForm(false)}
        isEditable={false}
      />
      <AddLead
        fetchData={() => props.refreshData()}
        open={onClickLeadForm}
        handleClose={() => setOnClickLeadForm(false)}
      />
      <Dialog
        open={logoutModal}
        onClose={() => {
          setLogoutModal(false);
        }}
      >
        <DialogContent sx={{ p: "0px !important" }}>
          <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                backgroundColor: "red",
                py: 2,
                px: 2.2,
                width: "fit-content",
                borderRadius: "50px",
              }}
            >
              <img src={white_logout} alt="delete" />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
              <Typography
                variant="medium_16px"
                color={sosColors["headingText"]}
              >
                Logout
              </Typography>
              <Typography variant="regular_13px" color={sosColors["secondary"]}>
                Are you sure you want to logout?
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ my: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ height: "30px", width: "fit-content", m: 0 }}
            onClick={() => {
              setLogoutModal(false);
            }}
          >
            Cancel
          </Button>
          <SubmitButton
            onClick={handleLogout}
            variant="contained"
            style={{ height: "30px", width: "fit-content", m: 0 }}
            text={"Logout"}
          />
        </DialogActions>
      </Dialog>
      <ChangePassword
        open={changePasswordModal}
        handleClose={() => setChangePasswordModal(false)}
      />
    </Box>
  );
};
export default DashboardLayout;
