import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { sosColors } from "../constants/theme/colors";
import styled from "@emotion/styled";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: sosColors["primary"],
    color: sosColors["headingText"],
    height: "36px",
    padding: "0 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "36px",
    padding: "0 16px",
  },
  "@media (min-width: 1600px) and (max-width: 2100px)": {
    [`&.${tableCellClasses.head}`]: {
      height: "55px !important",
    },
    [`&.${tableCellClasses.body}`]: {
      height: "55px !important",
    },
  },
  "@media (min-height: 750px) and (max-height: 2100px)": {
    [`&.${tableCellClasses.head}`]: {
      height: "55px !important",
    },
    [`&.${tableCellClasses.body}`]: {
      height: "55px !important",
    },
  },
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  "&.MuiTableContainer-root": {
    scrollbarColor: sosColors["primary"],
    scrollbarWidth: "thin",
    boxShadow: "0px 10px 20px 0px #0000001F",
    borderRadius: "5px",
  },
  "&.MuiTableContainer-root::-webkit-scrollbar": {
    width: "2px !important",
    height: "2px !important",
  },
  "&.MuiTableContainer-root::-webkit-scrollbar-track": {
    background: sosColors["primary"],
  },
  "&.MuiTableContainer-root::-webkit-scrollbar-thumb": {
    background: "#888 !important",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "transparent",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ScrollBox = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    paddingRight: "8px",
    overflowY: "auto",
    scrollbarColor: sosColors["primary"],
    scrollbarWidth: "thin",
    overflowX: "hidden",
  },
  "&.MuiBox-root::-webkit-scrollbar": {
    width: "2px !important",
  },
  "&.MuiBox-root::-webkit-scrollbar-track": {
    background: sosColors["primary"],
  },
  "&.MuiBox-root::-webkit-scrollbar-thumb": {
    background: "#888 !important",
  },
}));
