import styled from "@emotion/styled";
import { InputLabel, TextField } from "@mui/material";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  ".MuiInputBase-root": {
    border: "1px solid #E4E4E4",
    borderRadius: "5px",
    color: "#1F6FA1",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0em",
    // fontFamily: "Roboto",
  },
  ".MuiInputBase-root.Mui-focused": {
    borderColor: "#176FA1",
    color: "#1F6FA1",
    // fontFamily: "Roboto",
  },
  " .MuiOutlinedInput-input::placeholder": {
    // fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0em",
    color: "#AFAFAF !important",
    textAlign: "left",
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  ".MuiInputBase-input": {
    padding: "8px 12px",
  },
}));

export const StyledLabel = styled(InputLabel)(({ theme }) => ({
  ".MuiFormLabel-root": {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "left",
  },
}));

export const StyledSmallTextField = styled(TextField)(({ theme }) => ({
  ".MuiInputBase-root": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0em",
    textAlign: "left",
    border: "1px solid #E4E4E4",
    borderRadius: "5px",
    color: "#1F6FA1",
    // fontFamily: "Roboto",
  },
  ".MuiInputBase-root.Mui-focused": {
    borderColor: "#176FA1",
    color: "#1F6FA1",
    // fontFamily: "Roboto",
  },
  " .MuiOutlinedInput-input::placeholder": {
    // fontFamily: "Roboto",
    fontSize: "12px !important",
    fontWeight: 400,
    lineHeight: "14px",
    // letterSpacing: "0em",
    textAlign: "left",
    color: "#AFAFAF !important",
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  ".MuiInputBase-input": {
    padding: "0px 5px",
  },
}));
