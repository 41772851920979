import React from "react";
import { Props, SmallInputField } from "../../constants/InputField/input";
import search from "../../assets/icons/search.svg";

const SearchBox = (props: Props) => {
  return (
    <div>
      <SmallInputField
        name="searchbox"
        {...props}
        height="28px !important"
        inputAdornmentComp={<img src={search} alt="search" />}
      />
    </div>
  );
};

export default SearchBox;
