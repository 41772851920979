import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { getAllStatus } from "../../services";
import { toast } from "react-toastify";

// Define a type for the slice state
interface State {
  leads: any;
  status: any;
  loading: boolean;
  error: boolean;
  leadsPagingData: any;
  lastLead: Object;
}

// Define the initial state using that type
const initialState: State = {
  loading: false,
  leads: [],
  status: [],
  lastLead: {},
  leadsPagingData: {},
  error: false,
};

export const fetchStatus = createAsyncThunk("leads/fetchStatus", async () => {
  try {
    const response: any = await getAllStatus();
    return response?.data?.status;
  } catch (err: any) {
    toast.error(err);
  }
});

export const leadSlice = createSlice({
  name: "leads",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: initialState,
  reducers: {
    saveLeads: (state, action: PayloadAction<any>) => {
      state.leads = action.payload.leads;
      state.leadsPagingData = action.payload.data;
    },
    saveLastLead: (state, action: PayloadAction<any>) => {
      state.lastLead = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchStatus.pending, (state: State, action: any) => {
        state.loading = true;
      })
      .addCase(fetchStatus.fulfilled, (state: State, action: any) => {
        state.status = action.payload;
      })
      .addCase(fetchStatus.rejected, (state: State, action: any) => {
        state.error = true;
      });
  },
});

export const { saveLeads, saveLastLead } = leadSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const leads = (state: RootState) => state.leadData;

export default leadSlice.reducer;
