import { Box, Typography } from "@mui/material";
import React from "react";
import brand_logo from "../assets/logos/brand_logo.svg";
import brand_logo_2 from "../assets/logos/brand_logo_2.svg";
import video1 from "../assets/videos/video1.mp4";
import video2 from "../assets/videos/video2.mp4";
import "./style.css";
import { sosColors } from "../constants/theme/colors";

const AuthenticationLayout = (props: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        overflowY: "hidden",
      }}
    >
      <Box sx={{ width: "50%" }}>
        <div className="authenticationVideoContainer">
          <div className="one">
            <video className="video-background" autoPlay loop muted>
              <source src={video1} type="video/mp4" />
            </video>
          </div>
          <div className="two">
            <video autoPlay loop muted className="video-background">
              <source src={video2} type="video/mp4" />
            </video>
          </div>
        </div>
        <Box sx={{ position: "absolute", top: "100px", left: "3%" }}>
          <Box sx={{ width: "30%" }}>
            <Typography variant="heading_1" color={sosColors["white"]}>
              Atlantic Sports Group
            </Typography>
          </Box>
          <Box sx={{ width: "40%", mt: "25px" }}>
            <Typography variant="heading_2" color={sosColors["white"]}>
              The next step is the installation of the turf itself, which we are
              proficient in.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box id="authContent" sx={{ width: "50%", padding: "100px" }}>
        <Box sx={{ display: "flex", alignItems: "center", ml: "-30px" }}>
          <img
            src={brand_logo}
            alt="Atlantic Sports Group"
            width={"180px"}
            style={{ margin: "0 4% 0 0" }}
          />
          <img
            src={brand_logo_2}
            alt="Atlantic Sports Group"
            width={"280px"}
            style={{ margin: "0 0 0 4%" }}
          />
        </Box>
        <Box sx={{ width: "100%", mt: "50px" }}>{props.children}</Box>
      </Box>
    </Box>
  );
};

export default AuthenticationLayout;
