import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sosColors, sosStatusColors } from "../../constants/theme/colors";
import {
  addNotes,
  deleteNote,
  deleteReminder,
  getAllUsers,
  getLeadNotes,
  getLeadReminders,
  getSingleLead,
  updateLeadSalesPerson,
  updateStatus,
} from "../../services";
import { toast } from "react-toastify";
import DashboardLayout from "../../layout/DashboardLayout";
import details_project_icon from "../../assets/icons/details_project_icon.svg";
import _ from "lodash";
import moment from "moment";
import back_icon from "../../assets/icons/back_icon.svg";
import comment from "../../assets/icons/comment.svg";
import plus from "../../assets/icons/plus.svg";
import time from "../../assets/icons/time.svg";
import white_edit from "../../assets/icons/white_edit.svg";
import blue_drop_arrow from "../../assets/icons/blue_drop_arrow.svg";
import blue_down_chevron from "../../assets/icons/blue_down_chevron.svg";
import black_delete from "../../assets/icons/black_delete.svg";
import { numberWithCommas } from "../../utils/funtions";
import { BlueBox, ContentBox, StatusTag, WhiteBox } from "./style";
import { SmallInputField } from "../../constants/InputField/input";
import { fetchStatus } from "../../store/reducers/leadSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import SubmitButton from "../../components/SubmitButton";
import AddLead from "../../components/AddLead";
import AddReminders from "../../components/AddReminders";
import OnScreenLoader from "../../components/OnScreenLoader";
import DeleteModal from "../../components/DeleteModal";
import "./style.css";
import { saveUsers } from "../../store/reducers/userSlice";

function LeadDetails() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state);
  const [dataLoader, setDataLoader] = useState(true);
  const [note, setNote] = useState<string>("");
  const [noteLoader, setNoderLoader] = useState(false);
  const [reminderDialog, setReminderDialog] = useState(false);
  const [data, setData] = useState<any>({});
  const [notesData, setNotesData] = useState([]);
  const [reminderData, setReminderData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [userSelectionList, setUserSeletionList] = useState<Array<any>>([]);
  const [onClickLeadForm, setOnClickLeadForm] = React.useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState({
    label: "",
    open: false,
    id: "",
  });
  const [statusLoader, setStatusLoader] = useState(false);
  const [salesPersonLoader, setSalesPersonLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const open = Boolean(anchorEl);
  const openUser = Boolean(anchorElUser);

  useEffect(() => {
    let list: Array<any> = [];

    state.userData?.users?.map((user: any) => {
      if (user.id !== data?.sales_person?.id) {
        list.push({
          first_name: `${user.first_name}`,
          last_name: `${user.last_name}`,
          id: user.id,
        });
      }
    });
    setUserSeletionList([...list]);
  }, [data, state.userData.users]);

  useEffect(() => {
    document.body.style.overflowY = "auto";
    if (state?.leadData?.status?.length === 0) {
      dispatch(fetchStatus());
    }
    if (state?.userData?.users.length === 0) {
      getUserData();
    }
  }, []);

  useEffect(() => {
    getData();
    getNotes();
    getReminders();
  }, [params]);

  const getUserData = () => {
    getAllUsers()
      .then((response: any) => {
        if (response.status === 200 && response.data.status_code === 200) {
          dispatch(saveUsers(response.data.users));
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getData = () => {
    setDataLoader(true);
    getSingleLead({ id: params.id }).then((response: any) => {
      if (response.status === 200 && response.data.status_code === 200) {
        setData(response.data.lead);
        setDataLoader(false);
      } else {
        toast.error(response?.data?.error);
      }
    });
  };
  const getNotes = () => {
    getLeadNotes({ id: params.id }).then((response: any) => {
      if (response.status === 200 && response.data.status_code === 200) {
        setNotesData(response.data.notes);
      } else {
        toast.error(response?.data?.error);
      }
    });
  };

  const getReminders = () => {
    getLeadReminders({ id: params.id }).then((response: any) => {
      if (response.status === 200 && response.data.status_code === 200) {
        setReminderData(response.data.reminders);
      } else {
        toast.error(response?.data?.error);
      }
    });
  };

  const LabelValue = (label: string, value: string) => {
    return (
      <Box sx={{ mb: "10px", display: "flex" }}>
        <Typography variant="regular_14px" color={sosColors["text"]}>
          {label ?? ""}:
        </Typography>

        <Typography
          variant="medium_15px"
          color={sosColors["headingText"]}
          sx={{ ml: 1, wordWrap: "break-word" }}
        >
          {value ?? ""}
        </Typography>
      </Box>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const updateStatusFunc = (status: any) => {
    let leadData: any = { ...data };
    setStatusLoader(true);
    setAnchorEl(null);
    updateStatus({
      id: params.id,
      status: status.name,
    })
      .then((response: any) => {
        if (response.status === 201 && response.data.status_code === 201) {
          leadData.status = status;
          leadData.updated_at = moment().format();
          toast.success("Status updated successfully");
          setStatusLoader(false);
          setData(leadData);
          // getData();
        } else {
          setStatusLoader(false);
          toast.success(response.data.error);
        }
      })
      .catch((error) => {
        setStatusLoader(false);
      });
  };

  const updateSalesPersonFunc = (user: any) => {
    let leadData: any = { ...data };
    setSalesPersonLoader(true);
    setAnchorElUser(null);
    updateLeadSalesPerson({
      lead: params.id,
      newUser: user.id,
      oldUser: "",
      isMulti: false,
    })
      .then((response: any) => {
        if (response.status === 200 && response.data.status_code === 200) {
          leadData.sales_person = user;
          leadData.updated_at = moment().format();
          toast.success("Sales person updated successfully");
          setData(leadData);
          setSalesPersonLoader(false);
          // getData();
        } else {
          setSalesPersonLoader(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setSalesPersonLoader(false);
      });
  };

  const submitNote = () => {
    setNoderLoader(true);
    addNotes({
      lead_id: params.id,
      note: note.trim(),
    }).then((response: any) => {
      if (response.status === 201 && response.data.status_code === 201) {
        toast.success("Note added successfully");
        setNoderLoader(false);
        getNotes();
        setNote("");
      } else {
        setNoderLoader(false);
        toast.error(response.data?.error);
      }
    });
  };

  const handleDeleteSubmit = () => {
    setDeleteLoader(true);
    if (deleteModal.label === "note") {
      deleteNote({ id: deleteModal.id })
        .then((response: any) => {
          if (response.status === 200 && response.data.status_code === 200) {
            getNotes();
            setDeleteLoader(false);
            setDeleteModal({
              open: false,
              label: "",
              id: "",
            });
            toast.success("Note deleted successfully");
          } else {
            setDeleteLoader(false);
            toast.error(response?.data?.error);
          }
        })
        .catch(() => {
          setDeleteLoader(false);
        });
    } else {
      deleteReminder({ id: deleteModal.id })
        .then((response: any) => {
          if (response.status === 200 && response.data.status_code === 200) {
            getReminders();
            setDeleteModal({
              open: false,
              label: "",
              id: "",
            });
            setDeleteLoader(false);
            toast.success("Reminder deleted successfully");
          } else {
            setDeleteLoader(false);
            toast.error(response?.data?.error);
          }
        })
        .catch(() => {
          setDeleteLoader(false);
        });
    }
  };

  return (
    <DashboardLayout>
      {!dataLoader ? (
        <Box sx={{ width: "100%" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: "fit-content", height: "34px" }}
            onClick={() => window.history.back()}
          >
            <img src={back_icon} alt="icon" style={{ marginRight: "8px" }} />
            Back
          </Button>
          <ContentBox sx={{ mt: 2 }}>
            <BlueBox sx={{ justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={details_project_icon} alt="icon" />
                <Typography
                  variant="regular_24px"
                  color={sosColors["headingText"]}
                  sx={{ ml: 2 }}
                >
                  {/* {data?.project_type?.name} */}
                  {_.startCase(_.capitalize(data?.first_name))}{" "}
                  {_.startCase(_.capitalize(data?.last_name))}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    pr: 2,
                    borderRight: `1px solid #27AFE9`,
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="regular_14px"
                    color={sosColors["headingText"]}
                    sx={{ ml: 2 }}
                  >
                    Status:
                  </Typography>
                  <StatusTag
                    sx={{
                      borderColor: `${
                        sosStatusColors[data?.status?.order]
                      } !important`,
                      backgroundColor: `${
                        sosStatusColors[data?.status?.order]
                      }40`,
                      ml: 1,
                      mr: 1,
                    }}
                  >
                    {data?.status?.name}
                  </StatusTag>
                  {!statusLoader ? (
                    <IconButton onClick={handleClick}>
                      <img src={blue_drop_arrow} alt="icon" />
                    </IconButton>
                  ) : (
                    <>
                      <CircularProgress
                        style={{ height: "8px", width: "8px" }}
                        sx={{ color: sosColors["white"] }}
                      />
                    </>
                  )}
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    sx={{
                      mt: 1,
                      "& .MuiList-root": {
                        p: 0,
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    onClose={() => setAnchorEl(null)}
                  >
                    {state?.leadData?.status?.map((status: any) => (
                      <MenuItem
                        onClick={() => updateStatusFunc(status)}
                        sx={{
                          borderTop: `1px solid ${sosColors["divider"]}`,
                          py: 1,
                          pr: 4,
                        }}
                      >
                        <StatusTag
                          sx={{
                            borderColor: `${
                              sosStatusColors[status.order]
                            } !important`,
                            backgroundColor: `${
                              sosStatusColors[status.order]
                            }40`,
                          }}
                        >
                          {status?.name}
                        </StatusTag>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ hieght: "34px", width: "34px", p: 1, ml: 2 }}
                  onClick={() => setOnClickLeadForm(true)}
                >
                  <img src={white_edit} alt="edit" />
                </Button>
              </Box>
            </BlueBox>
            <WhiteBox>
              <Box sx={{ display: "flex", pb: 1 }}>
                <Box sx={{ width: "400px" }}>
                  {LabelValue(
                    "Company Name",
                    `${
                      data?.company_name !== "" && data.company_name
                        ? data?.company_name
                        : "NA"
                    } `
                  )}
                  {LabelValue("Email", `${data?.email} `)}
                  {LabelValue("Phone No", `${data?.phone}`)}
                  {LabelValue(
                    "Date Added",
                    `${moment(data?.created_at).format("DD MMM, YYYY")}`
                  )}
                </Box>
                <Box
                  sx={{
                    width: "25%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  {LabelValue(
                    "Address",
                    `${data?.address_line_1 + ","} ${data?.street}, ${
                      data?.city
                    }, ${data?.state}, ${data?.postal_code}, ${data?.country}`
                  )}
                  {LabelValue(
                    "Last Updated",
                    `${moment(data?.updated_at).format("DD MMM, YYYY")}`
                  )}
                </Box>
              </Box>
              <Divider />
              <Box sx={{ py: 2, display: "flex" }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "400px" }}
                >
                  <Typography variant="regular_14px" color={sosColors["text"]}>
                    Project Type:
                  </Typography>
                  <Typography
                    variant="medium_15px"
                    color={sosColors["headingText"]}
                    sx={{ ml: 1 }}
                  >
                    {data?.project_type?.name}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="regular_14px" color={sosColors["text"]}>
                    Project Name:
                  </Typography>
                  <Typography
                    variant="medium_15px"
                    color={sosColors["headingText"]}
                    sx={{ ml: 1 }}
                  >
                    {data?.project_name !== "" && data.project_name
                      ? data?.project_name
                      : "NA"}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box sx={{ py: 2, display: "flex" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "400px",
                  }}
                >
                  <Typography
                    variant="regular_14px"
                    color={sosColors["text"]}
                    sx={{ mr: 1 }}
                  >
                    Total sq.ft:
                  </Typography>
                  <Typography
                    variant="medium_15px"
                    color={sosColors["headingText"]}
                  >
                    {numberWithCommas(data?.measurements)}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="regular_14px"
                    color={sosColors["text"]}
                    sx={{ mr: 0.5 }}
                  >
                    Bid Amount:
                  </Typography>
                  <Typography
                    variant="medium_15px"
                    color={sosColors["headingText"]}
                  >
                    ${data?.bid_value}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box sx={{ pt: 2, pb: 0 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="regular_14px"
                    color={sosColors["text"]}
                    sx={{ mr: 1 }}
                  >
                    Assigned To:
                  </Typography>
                  <Typography
                    variant="medium_15px"
                    color={sosColors["headingText"]}
                  >
                    {data?.sales_person?.first_name}{" "}
                    {data?.sales_person?.last_name}
                  </Typography>
                  {!salesPersonLoader ? (
                    <IconButton onClick={handleClickUser} sx={{ mt: 0.5 }}>
                      <img src={blue_down_chevron} alt="icon" />
                    </IconButton>
                  ) : (
                    <Box sx={{ ml: 1 }}>
                      <CircularProgress
                        style={{ height: "8px", width: "8px" }}
                        sx={{ color: sosColors["ascent2"] }}
                      />
                    </Box>
                  )}
                  <Menu
                    anchorEl={anchorElUser}
                    open={openUser}
                    sx={{
                      mt: 1,
                      "& .MuiList-root": {
                        p: 0,
                      },
                      maxHeight: "calc(100vh - 300px)",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    onClose={() => setAnchorElUser(null)}
                  >
                    {userSelectionList?.map((user: any) => (
                      <MenuItem
                        onClick={() => updateSalesPersonFunc(user)}
                        sx={{
                          borderTop: `1px solid ${sosColors["divider"]}`,
                          py: 1,
                          pr: 4,
                        }}
                      >
                        {user?.first_name} {user?.last_name}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Box>
            </WhiteBox>
          </ContentBox>
          <ContentBox sx={{ mt: 2 }}>
            <WhiteBox>
              {" "}
              <Box
                sx={{
                  py: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={time} alt="icon" />
                  <Typography
                    variant="medium_14px"
                    color={sosColors["headingText"]}
                    sx={{ mx: 1 }}
                  >
                    Set Reminder
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: "fit-content", m: 0 }}
                  onClick={() => setReminderDialog(true)}
                >
                  <img src={plus} alt="icon" style={{ marginRight: "8px" }} />
                  New Reminder
                </Button>
              </Box>
              {reminderData?.map((remider: any) => (
                <>
                  <Divider />
                  <Box
                    sx={{
                      py: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{
                            pr: 2,
                            borderRight: `1px solid ${sosColors["divider"]}`,
                          }}
                        >
                          {LabelValue(
                            "Time",
                            moment(remider.date_time).local().format("hh:mm A")
                          )}
                        </Box>
                        <Box sx={{ ml: 2 }}>
                          {LabelValue(
                            "Date",
                            moment(remider.date_time)
                              .local()
                              .format("DD MMM, YYYY")
                          )}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <Typography
                          variant="regular_14px"
                          color={sosColors["text"]}
                        >
                          Comment:
                        </Typography>
                        <Typography
                          variant="regular_13px"
                          color={sosColors["text"]}
                          sx={{ ml: 1 }}
                        >
                          {remider.comment}
                        </Typography>
                      </Box>
                    </Box>
                    <IconButton
                      onClick={() =>
                        setDeleteModal({
                          id: remider.id,
                          open: true,
                          label: "reminder",
                        })
                      }
                    >
                      <img src={black_delete} alt="Delete" />
                    </IconButton>
                  </Box>
                </>
              ))}
            </WhiteBox>
          </ContentBox>
          <ContentBox sx={{ mt: 2 }}>
            <WhiteBox>
              {" "}
              <Box
                sx={{
                  py: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={comment} alt="icon" />
                  <Typography
                    variant="medium_14px"
                    color={sosColors["headingText"]}
                    sx={{ mx: 1 }}
                  >
                    Notes
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box sx={{ py: 2 }}>
                <SmallInputField
                  name="comment"
                  fullWidth={true}
                  value={note}
                  onChange={(e: any) => setNote(e?.target?.value)}
                  isLabel={true}
                  isValid={
                    note.trim().length >= 0 && note.trim().length <= 5000
                  }
                  label="Add Notes"
                  placeholder="Type here"
                  labelVariant="regular_13px"
                  message="Character limit for note is 5000"
                  rows={7}
                  inputAdornmentComp={
                    <SubmitButton
                      disabled={note.trim() === "" || note.length > 5000}
                      loading={noteLoader}
                      variant="contained"
                      style={{ mt: "80px" }}
                      onClick={() => submitNote()}
                      text="Add"
                    />
                  }
                  multiline={true}
                />

                <Box>
                  {notesData?.map((note: any) => (
                    <Box sx={{ mt: 2.5 }}>
                      <Divider />
                      <Box sx={{ mt: 2.5 }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="regular_14px"
                            color={sosColors["text"]}
                          >
                            {moment(note?.created_at)
                              .local()
                              .format("DD MMM, YYYY | hh:mm A")}
                          </Typography>
                          <IconButton
                            onClick={() =>
                              setDeleteModal({
                                label: "note",
                                open: true,
                                id: note.id,
                              })
                            }
                          >
                            <img src={black_delete} alt="icon" />
                          </IconButton>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <Typography
                            variant="regular_14px"
                            color={sosColors["headingText"]}
                          >
                            {note.note}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
                {data.comment ? (
                  <Box sx={{ mt: 2.5 }}>
                    <Divider />
                    <Box sx={{ mt: 2.5 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="regular_14px"
                          color={sosColors["text"]}
                        >
                          {moment(data?.created_at)
                            .local()
                            .format("DD MMM, YYYY | hh:mm A")}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Typography
                          variant="regular_14px"
                          color={sosColors["headingText"]}
                        >
                          {data.comment}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </WhiteBox>
          </ContentBox>
          <AddLead
            editMode={true}
            data={data}
            fetchData={getData}
            open={onClickLeadForm}
            handleClose={() => setOnClickLeadForm(false)}
          />
          <AddReminders
            leadData={data}
            open={reminderDialog}
            handleClose={() => setReminderDialog(false)}
            fetchData={() => getReminders()}
          />
          <DeleteModal
            open={deleteModal.open}
            label={deleteModal.label}
            loader={deleteLoader}
            handleClose={() =>
              setDeleteModal({
                label: "",
                open: false,
                id: "",
              })
            }
            handleSubmit={() => handleDeleteSubmit()}
          />
        </Box>
      ) : (
        <OnScreenLoader isLoading={dataLoader} />
      )}
    </DashboardLayout>
  );
}

export default LeadDetails;
