import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout/DashboardLayout";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { sosColors } from "../../constants/theme/colors";
import TableComponent from "../../components/Table";
import { getAllUsers } from "../../services";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "@emotion/styled";
import moment from "moment";
import deleteIcon from "../../assets/icons/delete.svg";
import editIcon from "../../assets/icons/edit.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { saveUsers } from "../../store/reducers/userSlice";
import { StyledTableContainer } from "../../common/style";
import OnScreenLoader from "../../components/OnScreenLoader";
import AddSalesPerson from "../../components/AddSalesPerson";
import DeleteUser from "../../components/DeleteUser";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: sosColors["primary"],
    color: sosColors["headingText"],
    height: "36px",
    padding: "0 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "36px",
    padding: "0 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "transparent",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Settings = () => {
  const [data, setData] = useState([]);
  const reduxState = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [pageLoader, setPageLoader] = useState(false);
  const [onClickUserForm, setOnClickUserForm] = React.useState<boolean>(false);
  const [userFormData, setUserFormData] = useState<Object>({});
  const [onClickDeleteUser, setOnClickDeleteUser] = React.useState<any>({
    open: false,
    data: {},
  });

  useEffect(() => {
    document.body.style.background = sosColors["pageBackground"];
    if (reduxState?.userData?.users?.length === 0) {
      getData();
    }
  }, []);

  const getData = () => {
    setPageLoader(true);
    getAllUsers()
      .then((response: any) => {
        if (response.status === 200 && response.data.status_code === 200) {
          dispatch(saveUsers(response.data.users));
          setPageLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tableConfig = [
    {
      label: "Sales Person",
      values: ["first_name", "last_name"],
    },
    {
      label: "Email Id",
      values: ["email"],
    },
    {
      label: "Company Name",
      values: ["company_name"],
    },
    {
      label: "Date Added",
      values: ["created_at"],
    },

    {
      label: " ",
      values: ["edit"],
    },
    {
      label: " ",
      values: ["delete"],
    },
  ];
  return (
    <DashboardLayout>
      <Box sx={{ width: "100%" }}>
        <Typography variant="regular_20px" color={sosColors["headingText"]}>
          List of sales people
        </Typography>
        <Box sx={{ mt: 2, width: "100%" }}>
          {reduxState?.userData?.users.length > 0 ? (
            <StyledTableContainer
              // component={Paper}
              sx={{
                maxHeight: "calc(100vh - 150px)",
                overflowY: "auto",
                backgroundColor: "transparent",
              }}
              id="scrollableDiv"
            >
              <InfiniteScroll
                style={{ backgroundColor: "transparent", overflow: "unset" }}
                loader={true}
                dataLength={15}
                next={() => {
                  let Data: any = [...data];

                  setData(Data);
                }}
                hasMore={true}
                scrollableTarget="scrollableDiv"
              >
                <Table
                  sx={{ minWidth: 700, backgroundColor: "transparent" }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      {tableConfig?.map((config: any) => (
                        <StyledTableCell>
                          <Typography
                            variant="medium_14px"
                            color={sosColors["headingText"]}
                            sx={{ fontWeight: "600 !important" }}
                          >
                            {config.label}
                          </Typography>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody sx={{ backgroundColor: "transparent" }}>
                    {reduxState?.userData?.users?.map((row: any) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell sx={{ width: "30%" }}>
                          <Typography
                            variant="regular_14px"
                            color={sosColors["text"]}
                          >
                            {row.first_name} {row.last_name}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "30%" }}>
                          <Typography
                            variant="regular_14px"
                            color={sosColors["text"]}
                          >
                            {row.email}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "10%" }}>
                          <Typography
                            variant="regular_14px"
                            color={sosColors["text"]}
                          >
                            {row.company_name && row.company_name !== ""
                              ? row.company_name
                              : "No Company"}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "15%" }}>
                          <Typography
                            variant="regular_14px"
                            color={sosColors["text"]}
                          >
                            {moment(row.created_at).format("DD MMM, YYYY")}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "2.5%" }}>
                          <IconButton
                            onClick={() => {
                              setOnClickUserForm(true);
                              setUserFormData(row);
                            }}
                          >
                            <img src={editIcon} alt="edit" />
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "2.5%" }}>
                          <IconButton
                            onClick={() =>
                              setOnClickDeleteUser({
                                open: true,
                                data: row,
                              })
                            }
                          >
                            <img src={deleteIcon} alt="edit" />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </StyledTableContainer>
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="medium_16px"
                color={sosColors["headingText"]}
              >
                No users found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <OnScreenLoader isLoading={pageLoader} />
      <AddSalesPerson
        open={onClickUserForm}
        handleClose={() => setOnClickUserForm(false)}
        isEditable={true}
        editData={userFormData}
      />
      <DeleteUser
        open={onClickDeleteUser.open}
        handleClose={() => setOnClickDeleteUser({ open: false, data: {} })}
        data={onClickDeleteUser.data}
        fetchData={getData}
      />
    </DashboardLayout>
  );
};

export default Settings;
