import {
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { StyledSmallTextField, StyledTextField } from "./style";
import "./style.css";
import { useState } from "react";
import closed_eye from "../../assets/icons/closed_eye.svg";
import open_eye from "../../assets/icons/open_eye.svg";
import { sosColors } from "../theme/colors";

export interface Props {
  label?: string;
  value?: any;
  color?: any;
  name?: string;
  onChange?: any;
  onBlur?: any;
  variant?: string;
  isValid?: boolean;
  message?: string;
  type?: string;
  placeholder?: string;
  classes?: any;
  fullWidth?: boolean;
  style?: any;
  inputAdornmentComp?: any;
  isRequired?: boolean;
  isLabel?: boolean;
  disabled?: boolean;
  className?: string;
  allowFloat?: boolean;
  inputProps?: any;
  maxNumberLength?: number;
  multiline?: boolean;
  rows?: number;
  autofocus?: boolean;
  sx?: any;
  onKeyPress?: any;
  InputProps?: any;
  ref?: any;
  height?: string;
  labelVariant?: string;
}

export const InputField = (props: Props) => {
  return (
    <>
      {props.isLabel && (
        <InputLabel
          sx={{ mb: "5px" }}
          htmlFor={props.name}
          required={props.isRequired}
          className="inputfield-label"
        >
          <Typography
            color={sosColors["secondary"]}
            variant={props.labelVariant ?? "medium_18px"}
          >
            {props.label}
          </Typography>
        </InputLabel>
      )}
      <StyledTextField
        id={props.name}
        type={props.type}
        className={props.className}
        // onWheel={(e) => e.target.blur()}
        classes={props.classes}
        error={!props.isValid}
        ref={props.ref}
        style={props.style}
        sx={{
          "& .MuiInputBase-input": {
            height: props.height,
          },
          "& .MuiInputBase-root": {
            borderColor:
              props.value !== "" && props.isValid
                ? `${sosColors["ascent2"]} !important`
                : `${sosColors["divider"]} !important`,
          },
        }}
        variant={"outlined"}
        autoComplete="off"
        autoFocus={props.autofocus}
        color={props.color}
        disabled={props.disabled}
        InputProps={{
          ...props.InputProps,
          endAdornment: props.inputAdornmentComp && (
            <InputAdornment position="end">
              {props.inputAdornmentComp}
            </InputAdornment>
          ),
        }}
        onKeyDown={props.onKeyPress}
        inputProps={props.inputProps}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        fullWidth={props.fullWidth}
        name={props.name}
        helperText={
          !props.isValid ? (
            <Typography data-testid={props.name}>{props.message}</Typography>
          ) : null
        }
        multiline={props.multiline}
        rows={props.rows}
      />
    </>
  );
};

export const SmallInputField = (props: Props) => {
  return (
    <>
      {props.isLabel && (
        <InputLabel
          sx={{ mb: "5px" }}
          htmlFor={props.name}
          required={props.isRequired}
          className="inputfield-label"
        >
          <Typography
            color={sosColors["secondary"]}
            variant={props.labelVariant ?? "medium_18px"}
          >
            {props.label}
          </Typography>
        </InputLabel>
      )}
      <StyledSmallTextField
        id={props.name}
        type={props.type}
        className={props.className}
        // onWheel={(e) => e.target.blur()}
        classes={props.classes}
        error={!props.isValid}
        ref={props.ref}
        style={props.style}
        sx={{
          "& .MuiInputBase-input": {
            height: props.height,
          },
          "& .MuiInputBase-root": {
            borderColor:
              props.value !== "" && props.isValid
                ? `${sosColors["ascent2"]} !important`
                : `${sosColors["divider"]} !important`,
          },
        }}
        variant={"outlined"}
        autoComplete="off"
        autoFocus={props.autofocus}
        color={props.color}
        disabled={props.disabled}
        InputProps={{
          ...props.InputProps,
          endAdornment: props.inputAdornmentComp && (
            <InputAdornment position="end">
              {props.inputAdornmentComp}
            </InputAdornment>
          ),
        }}
        onKeyDown={props.onKeyPress}
        inputProps={props.inputProps}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        fullWidth={props.fullWidth}
        name={props.name}
        helperText={
          !props.isValid ? (
            <Typography
              sx={{ ml: "-14px" }}
              data-testid={props.name}
              variant="regular_12px"
            >
              {props.message}
            </Typography>
          ) : null
        }
        multiline={props.multiline}
        rows={props.rows}
      />
    </>
  );
};

export const InputPasswordField = (props: Props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const handleClickShowPassword = () => {
    setIsPasswordVisible(isPasswordVisible ? false : true);
  };
  console.log(props.value, props.isValid);
  return (
    <div>
      <InputLabel
        sx={{ mb: "10px" }}
        data-testid={`${props.name}Label`}
        htmlFor={props.name}
        required={props.isRequired}
        className="inputfield-label"
      >
        <Typography color={sosColors["secondary"]} variant="medium_18px">
          {props.label}
        </Typography>
      </InputLabel>
      <StyledTextField
        data-testid={`${props.name}Field`}
        id={props.name}
        type={isPasswordVisible ? "text" : "password"}
        variant={"outlined"}
        color={props.color}
        style={props.style}
        onChange={props.onChange}
        error={!props.isValid}
        classes={props.classes}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        value={props.value}
        fullWidth
        sx={{
          "& .MuiInputBase-root": {
            borderColor:
              props.value !== "" && props.isValid
                ? `${sosColors["ascent2"]} !important`
                : `${sosColors["divider"]} !important`,
          },
        }}
        name={props.name}
        onKeyDown={props.onKeyPress}
        helperText={
          <Typography data-testid={`${props.name}Message`}>
            {!props.isValid ? props.message : null}
          </Typography>
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {isPasswordVisible ? (
                  <img src={open_eye} alt="eye open" />
                ) : (
                  <img src={closed_eye} alt="eye closed" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={props.inputProps}
      />
    </div>
  );
};
