import React, { useEffect, useState } from "react";
import DialogBox from "../DialogBox";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import { sosColors } from "../../constants/theme/colors";
import SubmitButton from "../SubmitButton";
import white_delete from "../../assets/icons/white_delete.svg";
import DropDown from "../DropDown";
import { useAppSelector } from "../../store/hooks";
import { deleteUser, updateLeadSalesPerson } from "../../services";
import { toast } from "react-toastify";
import _ from "lodash";

interface Props {
  open: boolean;
  handleClose: Function;
  data: any;
  fetchData: Function;
}

const DeleteUser = ({
  open = false,
  handleClose = () => {},
  data = {},
  fetchData = () => {},
}: Props) => {
  const reduxState = useAppSelector((state) => state.userData);
  const [selectionList, setSelectionList] = useState([]);
  const [selected, setSelected] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if (data.lead_count === 0) {
      setDisableButton(false);
    } else if (!_.isEmpty(selected)) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [open, data, selected]);

  useEffect(() => {
    let list: any = [];
    reduxState?.users?.map((user: any) => {
      if (user.id !== data.id) {
        list.push({
          name: `${user.first_name} ${user.last_name}`,
          id: user.id,
        });
      }
    });
    setSelectionList(list);
  }, [reduxState?.users, open]);

  const handleDeleteUser = () => {
    setLoader(true);
    deleteUser({ id: data.id })
      .then((response: any) => {
        if (response.status === 200 && response.data.status_code === 200) {
          setLoader(false);
          fetchData();
          handleClose();
          toast.success("Sales Person deleted successfully");
        } else {
          setLoader(false);
          toast.error(response?.data?.error);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  const handleUpdateSalesPerson = () => {
    setLoader(true);
    updateLeadSalesPerson({
      isMulti: true,
      oldUser: data.id,
      newUser: selected.id,
    }).then((response: any) => {
      if (response.status === 200 && response.data.status_code === 200) {
        handleDeleteUser();
      } else {
        setLoader(false);
        toast.error(response?.data?.error);
      }
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogContent sx={{ p: "0px !important" }}>
          <Box sx={{ p: 2, display: "flex", alignItems: "flex-start" }}>
            <Box
              sx={{
                backgroundColor: "red",
                py: 3,
                px: 3.5,
                width: "fit-content",
                borderRadius: "50px",
              }}
            >
              <img src={white_delete} alt="delete" />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
              <Typography
                variant="medium_16px"
                color={sosColors["headingText"]}
              >
                Delete sales person
              </Typography>
              {data?.lead_count === 0 ? (
                <Typography
                  variant="regular_13px"
                  color={sosColors["secondary"]}
                >
                  Are you sure you want to delete the sales person?
                </Typography>
              ) : (
                <Box>
                  <Typography
                    variant="regular_13px"
                    color={sosColors["secondary"]}
                  >
                    There are few Leads assigned to this sales person.
                    <br /> <br /> You need to re-assign these Leads to some
                    other sales person in order to delete this sales person.
                  </Typography>
                  <Box sx={{ py: 1.5 }}>
                    <Typography
                      variant="regular_13px"
                      color={sosColors["secondary"]}
                    >
                      Assign sales person{"   "}*
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <DropDown
                        value={selected}
                        selectionList={selectionList}
                        placeholder="Sales person"
                        handleChange={(e: any) => setSelected(e)}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ my: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ height: "30px", width: "fit-content", m: 0 }}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <SubmitButton
            onClick={() =>
              data.lead_count === 0
                ? handleDeleteUser()
                : handleUpdateSalesPerson()
            }
            loading={loader}
            variant="contained"
            disabled={disableButton}
            style={{ height: "30px", width: "fit-content", m: 0 }}
            text={data.lead_count === 0 ? "Delete" : "Re-Assign and Delete"}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteUser;
