import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AuthenticationLayout from "../../layout/AuthenticationLayout";
import {
  InputField,
  InputPasswordField,
} from "../../constants/InputField/input";
import { sosColors } from "../../constants/theme/colors";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../utils/routes";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../firebase-config";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import SubmitButton from "../../components/SubmitButton";
import { toast } from "react-toastify";
import { validateEmail, validateValue } from "../../utils/validation";
import _ from "lodash";

export interface state {
  email: string;
  password: string;
  isValidEmail: boolean;
  isValidPassword: boolean;
}

const Login = () => {
  const navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const auth: any = getAuth(app);
  const [formData, setFormData] = useState<state>({
    email: "",
    password: "",
    isValidEmail: true,
    isValidPassword: true,
  });
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({});
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, [document.body]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let FormData: any = { ...formData };
    FormData[e.target.name] = e.target.value;
    setFormData(FormData);
  };

  const handleSubmit = () => {
    setLoader(true);
    signInWithEmailAndPassword(auth, formData.email, formData.password)
      .then((userCredential: any) => {
        // Signed in
        const user: any = userCredential.user;
        console.log(user);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("asg_access_token", user.accessToken);
        window.location.href = ROUTE_PATH.leads;
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(
          errorMessage.includes("(auth/invalid-credential)")
            ? "Invalid Credentials"
            : errorMessage
        );
        setLoader(false);
      });
  };

  const handleOnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let FormData: any = { ...formData };
    if (e.target.name === "email") {
      FormData.isValidEmail = validateEmail(e.target.value);
    } else {
      FormData.isValidPassword = validateValue(e.target.value);
    }
    setFormData(FormData);
  };

  return (
    <AuthenticationLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "75%" }}>
          <InputField
            onBlur={handleBlur}
            name={"email"}
            onChange={handleChange}
            fullWidth={true}
            isLabel={true}
            label="Email id"
            placeholder="Enter Email Id"
            isValid={formData.isValidEmail}
            value={formData.email}
            onKeyPress={handleOnKeyDown}
            message={
              formData.email === ""
                ? "Please enter an email"
                : "Please enter a valid email"
            }
          />
        </Box>
        <Box sx={{ width: "75%", my: "30px" }}>
          <InputPasswordField
            onBlur={handleBlur}
            onChange={handleChange}
            name={"password"}
            fullWidth={true}
            value={formData.password}
            isLabel={true}
            label="Password"
            placeholder="Enter password"
            isValid={formData.isValidPassword}
            onKeyPress={handleOnKeyDown}
            message={"Please enter an password"}
          />
        </Box>
        <Box>
          <Typography
            color={sosColors["ascent2"]}
            variant="regular_16px"
            sx={{
              ":hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
            onClick={() => navigate(ROUTE_PATH.forgot_password)}
          >
            Forgot Password?
          </Typography>
        </Box>
        <Box sx={{ width: "75%", mt: "30px" }}>
          <SubmitButton
            disabled={
              !(
                formData.email !== "" &&
                formData.password !== "" &&
                formData.isValidEmail &&
                formData.isValidPassword
              )
            }
            variant="contained"
            onClick={() => handleSubmit()}
            style={{ height: "51px" }}
            loading={loader}
            text="Login"
          />
        </Box>
      </Box>
    </AuthenticationLayout>
  );
};

export default Login;
