import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StyledNavTab = styled(Box)(({ theme }) => ({
  ".MuiBox-root": {
    // fontFamily: "Roboto",
    // fontSize: "14px !important",
    // fontWeight: 500,
    // lineHeight: "16px",
    // letterSpacing: "0em",
    width: "68px !important",
    height: "55px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}));
