import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    [variant: string]: true;
  }
}

export const themeTypography = createTheme(themePalette, {
  typography: {
    heading_1: {
      fontSize: "90px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "100px",
    },
    heading_2: {
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "32px",
    },
    bold_heading: {
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "37.5px",
    },
    medium_14px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    regular_14px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    medium_24px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "28.13px",
    },
    regular_24px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28.13px",
    },
    regular_12px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    semibold_14px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
    },
    medium_13px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    regular_13px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    medium_18px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "21.09px",
    },
    reuglar_18px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    reuglar_24px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    medium_16px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    medium_15px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    regular_16px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    bold_16px: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "19px",
    },
    regular_20px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "23.44px",
    },
    medium_20px: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "23.44px",
    },
  },
});
