import styled from "@emotion/styled";
import { FormControlLabel } from "@mui/material";
import { sosColors } from "../../constants/theme/colors";

export const StyledLabeledCheckbox = styled(FormControlLabel)(({ theme }) => ({
  "&.MuiFormControlLabel-root": {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    marginRight: 0,
    borderBottom: `1px solid ${sosColors["divider"]}`,
    borderTop: `1px solid ${sosColors["divider"]}`,
    paddingLeft: "16px",
  },
  "& .MuiFormControlLabel-label": {
    border: "1px solid",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "center",
    padding: "4px 8px",
    borderRadius: "4px",
  },
}));
