import React, { createRef, useCallback, useEffect, useState } from "react";
import DashboardLayout from "../../layout/DashboardLayout";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormGroup,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ScrollBox,
  StyledTableCell,
  StyledTableContainer,
  StyledTableRow,
} from "../../common/style";
import { sosColors, sosStatusColors } from "../../constants/theme/colors";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getAllLeads,
  getAllLeadsCount,
  getAllUnPaginatedLeads,
  getAllUsers,
  getProjectTypeList,
} from "../../services";
import FilterAccordion from "../../components/FilterAccordion";
import {
  fetchStatus,
  saveLastLead,
  saveLeads,
} from "../../store/reducers/leadSlice";
import { StyledLabeledCheckbox } from "../../components/LabeledCheckbox/style";
import DateRangePicker from "../../components/DateRangePicker";
import DropDown from "../../components/DropDown";
import SqRangePicker from "../../components/SqRangePicker";
import SearchBox from "../../components/SearchBox";
import _, { drop } from "lodash";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../utils/routes";
import { StatusTag } from "../LeadDetails/style";
import OnScreenLoader from "../../components/OnScreenLoader";
import black_filter from "../../assets/icons/black_filter.svg";
import { saveUsers } from "../../store/reducers/userSlice";
import { numberWithCommas } from "../../utils/funtions";
import { generateCSV } from "../../utils/generateCSV";
import { csvStructure } from "../../utils/csvStructure";
import { validateInput } from "../../utils/validation";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  limit,
  startAfter,
  DocumentData,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { createFilters, getDuplicatesById } from "./utils";

const Leads = () => {
  const tableRef: any = createRef();
  const reduxState = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [valueCount, setValueCount] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [userList, setUserList] = useState([]);
  const [statusSelection, setStatusSelection] = useState<any>({});
  const [statusList, setStatusList] = useState<any>([]);
  const [dateFilter, setDateFilter] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const [measurementFilter, setMeasurementFilter] = useState<any>({
    max: "",
    min: "",
  });
  const [projectTypeFilter, setProjectTypeFilter] = useState<any>({});
  const [salesPersonFilter, setSalesPersonFilter] = useState<any>({});
  const [projectList, setProjectList] = useState<any>([]);
  const [disableFilter, setDisableFilter] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [isValidSearchInput, setIsValidSearchInput] = useState(true);
  const [pageLoader, setPageLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [filterApplied, setFilterApplied] = useState(false);
  let mounted = true;
  useEffect(() => {
    document.body.style.background = sosColors["pageBackground"];
  }, []);

  useEffect(() => {
    if (tableRef.current !== null) {
      tableRef.current.scrollTop = 0;
    }
    setPageLoader(true);
    directDataCall([]);

    return () => {
      mounted = false;
    };
  }, []);
  var docRef: any = collection(db, `leads`);
  const statusRef = collection(db, `status`);
  const usersRef = collection(db, `users`);
  const projectTypeRef = collection(db, `project_type`);
  const directDataCall = (data: Array<any>) => {
    if (mounted) {
      let dropdata: any = [...data];
      let projectTypeList: any = [];
      let statusList: any = [];
      let users: any = [];
      let q = query(
        docRef,
        orderBy("updated_at", "desc"),
        startAfter(
          dropdata.length === 0 ? dropdata : reduxState?.leadData?.lastLead
        ),
        limit(20)
      );
      setFilterApplied(false);
      // setPageLoader(true);
      onSnapshot(projectTypeRef, (query) => {
        query.docs.map((doc: any) => {
          const data = doc.data();
          projectTypeList.push({ ...data, id: doc.id });
        });
      });
      onSnapshot(statusRef, (query) => {
        query.docs.map((doc: any) => {
          const data = doc.data();
          statusList.push({ ...data, id: doc.id });
        });
      });
      onSnapshot(usersRef, (query) => {
        query.docs.map((doc: any) => {
          const data = doc.data();
          users.push({ ...data, id: doc.id });
        });
      });
      onSnapshot(q, (querySnapshot: any) => {
        querySnapshot.docs.map((doc: any) => {
          const data = doc.data();

          let user: any = {};
          let status: any = {};
          let projectType: any = {};
          dispatch(
            saveLastLead(querySnapshot.docs[querySnapshot.docs.length - 1])
          );
          users.map((userdoc: any) => {
            // let udata=userDoc.data()
            if (userdoc.id === data.sales_person) {
              user = userdoc;
            }
          });
          projectTypeList.map((pdoc: any) => {
            //  console.log(pdoc.id,doc)
            if (pdoc.id === data.project_type) {
              projectType = pdoc;
            }
          });

          statusList.map((doc: any) => {
            //  const statusData = doc.data();
            if (doc.name === data?.status) {
              status = doc;
            }
          });
          // console.log(status, user, projectType, data);

          if (data.status !== "Job") {
            dropdata.push({
              id: doc.id,
              ...data,
              status: status,
              project_type: {
                name: `${projectType?.name}`,
                id: projectType.id,
              },
              sales_person: {
                first_name: `${user?.first_name}`,
                last_name: `${user?.last_name}`,
                id: user.id,
              },
            });
          }
        });
        setPageLoader(false);
        // setDummyData(dropdata);
        // setPaginatedData(dropPaginateData);

        dispatch(saveLeads({ leads: getDuplicatesById(dropdata), data: {} }));
      });

    }
  };

  const directDataCallFilters = async (): Promise<void> => {
    let count = 0;
    let dropdata: any[] = [];
    let projectTypeList: any[] = [];
    let statusList: any[] = [];
    let users: any[] = [];

    try {
      setFilterApplied(true);
      setPageLoader(true);
      let statusFilter: any = [];
      Object.keys(statusSelection).forEach((stat) => {
        if (statusSelection[stat] === true) {
          statusFilter.push(stat);
        }
      });
      // Fetch project types
      onSnapshot(projectTypeRef, (query) => {
        query.docs.map((doc: any) => {
          const data = doc.data();
          projectTypeList.push({ ...data, id: doc.id });
        });
      });
      onSnapshot(statusRef, (query) => {
        query.docs.map((doc: any) => {
          const data = doc.data();
          statusList.push({ ...data, id: doc.id });
        });
      });
      onSnapshot(usersRef, (query) => {
        query.docs.map((doc: any) => {
          const data = doc.data();
          users.push({ ...data, id: doc.id });
        });
      });
      let q = query(docRef, orderBy("project_name"));
      // Fetch documents from docRef query
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc: any) => {
        const data = doc.data() as DocumentData;
        let user: any = {};
        let status: any = {};
        let projectType: any = {};

        users.forEach((userdoc) => {
          if (userdoc.id === data.sales_person) {
            user = userdoc;
          }
        });

        projectTypeList.forEach((pdoc) => {
          if (pdoc.id === data.project_type) {
            projectType = pdoc;
          }
        });

        statusList.forEach((statusDoc) => {
          if (statusDoc.name === data?.status) {
            status = statusDoc;
          }
        });

        if (!statusFilter.includes("Job")) {
          if (data.status !== "Job") {
            dropdata.push({
              id: doc.id,
              ...data,
              status: status,
              project_type: {
                name: `${projectType?.name}`,
                id: projectType.id,
              },
              sales_person: {
                first_name: `${user?.first_name}`,
                last_name: `${user?.last_name}`,
                id: user.id,
              },
            });
          }
        } else if (statusFilter.includes("Job")) {
          if (data.status === "Job") {
            dropdata.push({
              id: doc.id,
              ...data,
              status: status,
              project_type: {
                name: `${projectType?.name}`,
                id: projectType.id,
              },
              sales_person: {
                first_name: `${user?.first_name}`,
                last_name: `${user?.last_name}`,
                id: user.id,
              },
            });
          }
        }
      });

      let createdFilters = createFilters({
        search: searchInput,
        minSq: measurementFilter.min,
        maxSq: measurementFilter.max,
        sales_person: salesPersonFilter.id,
        projectType: projectTypeFilter.id,
        statusReq: statusFilter,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
      });

      let filteredLeads = dropdata.filter((item: any) => {
        return createdFilters.every((filter: any) => filter(item));
      });

      getDuplicatesById(filteredLeads).forEach((data: any) => {
        count += data.bid_value;
      });

      setHasMore(false);
      setValueCount(count);
      setPageLoader(false);

      dispatch(
        saveLeads({ leads: getDuplicatesById(filteredLeads), data: {} })
      );
      setTotalLeads(getDuplicatesById(filteredLeads).length);
    } catch (error) {
      console.error("Error fetching data: ", error);
      // Handle errors here
    }
  };

  useEffect(() => {
    getProjectTypeList()
      .then((response) => {
        setProjectList(response?.data.types);
      })
      .catch((error) => console.log(error));
    dispatch(fetchStatus());
  }, []);

  useEffect(() => {
    if (reduxState?.userData?.users?.length === 0) {
      getAllUsers()
        .then((response: any) => {
          if (response.status === 200 && response.data.status_code === 200) {
            dispatch(saveUsers(response.data.users));
            setPageLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    let users = [];
    users = reduxState?.userData?.users.map((user: any) => {
      return { id: user.id, name: `${user.first_name} ${user.last_name}` };
    });
    setUserList(users);
  }, [reduxState?.userData?.users]);

  useEffect(() => {
    let list: any = {};
    reduxState?.leadData?.status?.map((status: any) => {
      list[status.name] = false;
    });
    setStatusList(reduxState?.leadData?.status);
    setStatusSelection(list);
  }, [reduxState?.leadData?.status]);

  useEffect(() => {
    let status: any = [];

    Object.keys(statusSelection).forEach((stat) => {
      if (statusSelection[stat] === true) {
        status.push(stat);
      }
    });

    if (
      status.length > 0 ||
      searchInput.trim().length > 0 ||
      (measurementFilter.min !== "" &&
        measurementFilter.max !== "" &&
        parseInt(measurementFilter.min) < parseInt(measurementFilter.max)) ||
      !_.isEmpty(projectTypeFilter) ||
      !_.isEmpty(salesPersonFilter) ||
      (dateFilter.startDate !== null &&
        dateFilter.endDate !== null &&
        moment(dateFilter.endDate).format() !== "Invalid date" &&
        moment(dateFilter.startDate).format() !== "Invalid date")
    ) {
      setDisableFilter(false);
    } else {
      setDisableFilter(true);
    }
  }, [
    projectTypeFilter,
    measurementFilter,
    statusSelection,
    dateFilter,
    searchInput,
    salesPersonFilter,
  ]);

  const tableConfig = [
    {
      label: "Project Name",
      values: ["project_name"],
    },
    {
      label: "Project Type",
      values: ["project_type"],
    },
    {
      label: "Sales Person",
      values: ["sales_person"],
    },
    {
      label: "Value",
      values: ["bid_value"],
    },
    {
      label: "Status",
      values: ["status"],
    },
    {
      label: "Last Updated",
      values: ["updated_at"],
    },
  ];

  const handleStageFilterChange = (status: any, value: boolean) => {
    let list: any = { ...statusSelection };
    if (status.name !== "Job") {
      list[status.name] = !list[status.name];
      list["Job"] = false;
      setStatusSelection(list);
    } else {
      let falselist: any = {};
      reduxState?.leadData?.status?.map((status: any) => {
        falselist[status.name] = false;
      });
      falselist["Job"] = !list["Job"];
      setStatusSelection(falselist);
    }
  };

  const clearFilters = () => {
    if (tableRef.current !== null) {
      tableRef.current.scrollTop = 0;
    }
    setStatusList([]);
    setProjectTypeFilter({});
    setMeasurementFilter({ max: "", min: "" });
    setDateFilter({ startDate: null, endDate: null });
    setSearchInput("");
    let list: any = { ...statusSelection };
    reduxState?.leadData?.status?.map((status: any) => {
      list[status.name] = false;
    });
    setSalesPersonFilter({});

    setValueCount(0);
    let dropdata: any = [];
    let projectTypeList: any = [];
    let statusList: any = [];
    let users: any = [];
    let q = query(
      docRef,
      orderBy("updated_at", "desc"),
      startAfter(
        dropdata.length === 0 ? dropdata : reduxState?.leadData?.lastLead
      ),
      limit(20)
    );

    setPageLoader(true);
    setFilterApplied(false);
    // setPageLoader(true);
    onSnapshot(projectTypeRef, (query) => {
      query.docs.map((doc: any) => {
        const data = doc.data();
        projectTypeList.push({ ...data, id: doc.id });
      });
    });
    onSnapshot(statusRef, (query) => {
      query.docs.map((doc: any) => {
        const data = doc.data();
        statusList.push({ ...data, id: doc.id });
      });
    });
    onSnapshot(usersRef, (query) => {
      query.docs.map((doc: any) => {
        const data = doc.data();
        users.push({ ...data, id: doc.id });
      });
    });
    onSnapshot(q, (querySnapshot: any) => {
      querySnapshot.docs.map((doc: any) => {
        const data = doc.data();

        let user: any = {};
        let status: any = {};
        let projectType: any = {};
        dispatch(
          saveLastLead(querySnapshot.docs[querySnapshot.docs.length - 1])
        );
        users.map((userdoc: any) => {
          // let udata=userDoc.data()
          if (userdoc.id === data.sales_person) {
            user = userdoc;
          }
        });
        projectTypeList.map((pdoc: any) => {
          //  console.log(pdoc.id,doc)
          if (pdoc.id === data.project_type) {
            projectType = pdoc;
          }
        });

        statusList.map((doc: any) => {
          //  const statusData = doc.data();
          if (doc.name === data?.status) {
            status = doc;
          }
        });
        // console.log(status, user, projectType, data);
        if (data.status !== "Job") {
          dropdata.push({
            id: doc.id,
            ...data,
            status: status,
            project_type: {
              name: `${projectType?.name}`,
              id: projectType.id,
            },
            sales_person: {
              first_name: `${user?.first_name}`,
              last_name: `${user?.last_name}`,
              id: user.id,
            },
          });
        }
      });
      setHasMore(true);
      setPageLoader(false);
      setStatusSelection(list);
      setStatusList(reduxState?.leadData?.status);
      setPageLoader(false);

      dispatch(saveLeads({ leads: getDuplicatesById(dropdata), data: {} }));
    });
  };

  const getCsvData = () => {
    let status: any = [];

    Object.keys(statusSelection).forEach((stat) => {
      if (statusSelection[stat] === true) {
        status.push(stat);
      }
    });
    setPageLoader(true);
    getAllUnPaginatedLeads({
      minSq: measurementFilter.min,
      maxSq: measurementFilter.max,
      type: projectTypeFilter.id,
      search: searchInput,
      status: status.toString(),
      startDate: dateFilter.startDate
        ? moment(dateFilter.startDate).utc().format()
        : "",
      endDate: dateFilter.endDate
        ? moment(dateFilter.endDate).add(1, "day").utc().format()
        : "",
      sales_person: salesPersonFilter.id,
    })
      .then((response: any) => {
        if (response.status === 200 && response.data.status_code === 200) {
          setPageLoader(false);
          generateCSV(
            csvStructure,
            response?.data?.leads,
            `Leads_${moment().format("MM-DD-YYYY")}`
          );
        }
      })
      .catch((err) => {
        setPageLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (reduxState?.leadData?.leads.length > 0) {
      if (!filterApplied) {
        getAllLeadsCount().then((response: any) => {
          setHasMore(true);
          setValueCount(response.data.totalValue);
          setTotalLeads(response.data.totalLeads);
        });
      }
    } else {
      setValueCount(0);
      setTotalLeads(0);
    }
  }, [reduxState?.leadData?.leads]);

  const handleSearhChange = (e: any) => {
    setSearchInput(e.target.value);
    setIsValidSearchInput(validateInput(e.target.value));
  };
  const handleOnKeyDown = (e: any) => {
    if (
      e.key === "Enter" &&
      searchInput.trim().length > 0 &&
      isValidSearchInput
    ) {
      directDataCallFilters();
      tableRef.current.scrollTop = 0;
    }
  };

  const TotalFeedBox = (head: string, value: string) => {
    return (
      <Box
        sx={{
          padding: "10px 25px",
          backgroundColor: sosColors["ascent2"],
          borderRadius: "10px",
          mb: 1.7,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="bold_16px" color={sosColors["ascent1"]}>
            {head}
          </Typography>
          <Typography variant="bold_heading" color={sosColors["white"]}>
            {value}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <DashboardLayout
      downloadCsv={() => getCsvData()}
      refreshData={() => clearFilters()}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ width: "22.5%" }}>
          <Box>
            {TotalFeedBox("Total Leads", totalLeads.toString())}
            {TotalFeedBox(
              "Total Sales Value",
              `$ ${numberWithCommas(valueCount)}`
            )}
          </Box>
          <Box
            sx={{
              boxShadow: "0px 10px 20px 0px #0000001F",
              borderRadius: "5px",
              // height: "100%",
            }}
          >
            <Box
              sx={{
                backgroundColor: sosColors["white"],
                p: 1,
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={black_filter} alt="icon" />
              <Typography
                variant={"medium_14px"}
                color={sosColors["headingText"]}
                sx={{ ml: 1 }}
              >
                Filter By
              </Typography>
            </Box>

            <ScrollBox
              sx={{
                p: 1,
                borderTop: "1px solid #F2F4F8",
                backgroundColor: "white",
                height: "calc(100vh - 400px)",
                maxHeight: "670px",
              }}
            >
              <Box>
                <SearchBox
                  value={searchInput}
                  onChange={(e: any) => handleSearhChange(e)}
                  message="Please enter a valid input"
                  onKeyPress={handleOnKeyDown}
                  isLabel={false}
                  placeholder="Search by lead name,email"
                  fullWidth={true}
                  isValid={isValidSearchInput}
                  height="28px"
                />
              </Box>
              <Box>
                <FilterAccordion
                  title="Status"
                  isValue={
                    !Object.values(statusSelection).every(
                      (item) => item === false
                    )
                  }
                >
                  <FormGroup>
                    {statusList?.map((status: any) => (
                      <StyledLabeledCheckbox
                        checked={statusSelection[status.name]}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            borderColor: `${
                              sosStatusColors[status.order]
                            } !important`,
                            backgroundColor: `${
                              sosStatusColors[status.order]
                            }40`,
                          },
                        }}
                        control={
                          <Checkbox
                            sx={{
                              color: `${sosColors["ascent2"]} !important`,
                            }}
                            checked={statusSelection[status.name]}
                            onChange={() =>
                              handleStageFilterChange(
                                status,
                                reduxState?.leadData?.status[status.name]
                              )
                            }
                            name={status.name}
                          />
                        }
                        label={status.name}
                      />
                    ))}
                  </FormGroup>
                </FilterAccordion>
                <FilterAccordion
                  title="Sales Person"
                  isValue={!_.isEmpty(salesPersonFilter)}
                >
                  <DropDown
                    placeholder="Select Sales Person"
                    selectionList={userList}
                    handleChange={(value: object) =>
                      setSalesPersonFilter(value)
                    }
                    value={salesPersonFilter}
                  />
                </FilterAccordion>
                <FilterAccordion
                  title="Date Range"
                  isValue={
                    dateFilter.startDate !== null && dateFilter.endDate !== null
                  }
                >
                  <DateRangePicker
                    getDate={(date: any) => setDateFilter(date)}
                    date={dateFilter}
                  />
                </FilterAccordion>
                <FilterAccordion
                  title="Project type"
                  isValue={!_.isEmpty(projectTypeFilter)}
                >
                  <DropDown
                    placeholder="Select Project Type"
                    selectionList={projectList}
                    handleChange={(value: object) =>
                      setProjectTypeFilter(value)
                    }
                    value={projectTypeFilter}
                  />
                </FilterAccordion>
                <FilterAccordion
                  title="Sq.ft Range"
                  isValue={
                    measurementFilter.min.trim() !== "" &&
                    measurementFilter.max.trim() !== ""
                  }
                >
                  <SqRangePicker
                    handleChange={(data: any) => setMeasurementFilter(data)}
                    values={measurementFilter}
                  />
                </FilterAccordion>
              </Box>
            </ScrollBox>
            <Divider />
            <Box
              sx={{
                py: 1,
                backgroundColor: "white",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            >
              <Button
                variant="contained"
                onClick={directDataCallFilters}
                color="primary"
                disabled={disableFilter}
                sx={{ width: "fit-content", height: "30px", mx: 2 }}
              >
                Apply Filter
              </Button>
              <Button
                onClick={clearFilters}
                // disabled={disableFilter}
                variant="outlined"
                color="primary"
                sx={{ width: "fit-content", height: "30px" }}
              >
                Clear
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "76.5%" }}>
          {reduxState?.leadData?.leads.length > 0 ? (
            <Box>
              <StyledTableContainer
                // component={Paper}
                sx={{
                  maxHeight: "950px",
                  height: "calc(100vh - 120px)",
                  overflowY: "auto",
                  overflowX: "auto",
                  backgroundColor: "transparent",
                }}
                id="scrollableDiv"
              >
                <InfiniteScroll
                  style={{
                    backgroundColor: "transparent",
                    overflow: "unset",
                  }}
                  loader={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "3px 0",
                      }}
                    >
                      {" "}
                      <CircularProgress
                        style={{ height: "20px", width: "20px" }}
                        sx={{ color: sosColors["ascent2"] }}
                      />
                    </div>
                  }
                  dataLength={reduxState?.leadData?.leads.length}
                  next={() => {
                    directDataCall(reduxState?.leadData?.leads);
                  }}
                  hasMore={
                    totalLeads === reduxState?.leadData?.leads.length
                      ? false
                      : hasMore
                  }
                  scrollableTarget="scrollableDiv"
                >
                  <Table
                    ref={tableRef}
                    sx={{ minWidth: 700, backgroundColor: "transparent" }}
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead sx={{ position: "sticky" }}>
                      <TableRow>
                        {tableConfig?.map((config: any) => (
                          <StyledTableCell>
                            <Typography
                              variant="medium_14px"
                              color={sosColors["headingText"]}
                              sx={{ fontWeight: "600 !important" }}
                            >
                              {config.label}
                            </Typography>
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody sx={{ backgroundColor: "transparent" }}>
                      {reduxState?.leadData?.leads?.map(
                        (row: any, index: any) => (
                          <StyledTableRow
                            key={index}
                            sx={{
                              cursor: "pointer",
                              ":hover": {
                                backgroundColor: `${sosColors["tableBackground"]} !important`,
                              },
                            }}
                            onClick={() =>
                              navigate(`${ROUTE_PATH.leads}/${row.id}`)
                            }
                          >
                            <StyledTableCell sx={{ width: "20%" }}>
                              <Typography
                                variant="regular_14px"
                                color={sosColors["text"]}
                              >
                                {row?.project_name?.trim() !== "" &&
                                row?.project_name
                                  ? row.project_name
                                  : "NA"}
                                {/* {" "}{_.startCase(_.capitalize(row.last_name))} */}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: "20%" }}>
                              <Typography
                                variant="regular_14px"
                                color={sosColors["text"]}
                              >
                                {row.project_type.name}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: "15%" }}>
                              <Typography
                                variant="regular_14px"
                                color={sosColors["text"]}
                              >
                                {_.startCase(
                                  _.capitalize(row?.sales_person?.first_name)
                                )}{" "}
                                {_.startCase(
                                  _.capitalize(row?.sales_person?.last_name)
                                )}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: "15%" }}>
                              <Typography
                                variant="regular_14px"
                                color={sosColors["text"]}
                              >
                                $ {numberWithCommas(row.bid_value)}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: "15%" }}>
                              <Typography
                                variant="regular_14px"
                                color={sosColors["text"]}
                              >
                                {row.status?.name && (
                                  <StatusTag
                                    sx={{
                                      borderColor: `${
                                        sosStatusColors[row?.status?.order]
                                      } !important`,
                                      backgroundColor: `${
                                        sosStatusColors[row?.status?.order]
                                      }40`,
                                      ml: 1,
                                      mr: 1,
                                      px: 1,
                                      width: "fit-content",
                                    }}
                                  >
                                    {row.status?.name}
                                  </StatusTag>
                                )}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: "15%" }}>
                              <Typography
                                variant="regular_14px"
                                color={sosColors["text"]}
                              >
                                {moment(row.updated_at).format("DD MMM, YYYY")}
                              </Typography>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </InfiniteScroll>
              </StyledTableContainer>
            </Box>
          ) : pageLoader ? (
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="medium_16px"
                color={sosColors["headingText"]}
              >
                Loading...
              </Typography>
            </Box>
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="medium_16px"
                color={sosColors["headingText"]}
              >
                No leads found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <OnScreenLoader isLoading={pageLoader} />
    </DashboardLayout>
  );
};

export default Leads;
