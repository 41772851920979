import React, { useEffect, useState } from "react";
import SubmitButton from "../SubmitButton";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from "@mui/material";
import DialogBox from "../DialogBox";
import { InputPasswordField } from "../../constants/InputField/input";
import {
  validateBlankSpace,
  validateConfirmPassword,
  validatePassword,
} from "../../utils/validation";
import { firebaseConfig } from "../../firebase-config";
import { initializeApp } from "firebase/app";
import {
  EmailAuthProvider,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { toast } from "react-toastify";

interface Props {
  open: boolean;
  handleClose: Function;
}

const ChangePassword = ({ open = false, handleClose = () => {} }: Props) => {

  const getUser: any = localStorage.getItem("user");
  const user: any = JSON.parse(getUser);

  const [state, setState] = useState({
    old_password: "",
    new_password: "",
    new_password_confirm: "",
    isValidOldPassword: true,
    isValidNewPasswordConfirm: true,
    isValidNewPassword: true,
  });
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const handleChange = (e: any) => {
    let data: any = { ...state };
    data[e.target.name] = e.target.value;
    setState(data);
  };

  useEffect(() => {
    if (
      state.old_password.trim() !== "" &&
      state.new_password.trim() !== "" &&
      state.new_password_confirm.trim() !== "" &&
      state.isValidOldPassword === true &&
      state.isValidNewPassword === true &&
      state.isValidNewPasswordConfirm === true
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [state]);

  const handleBlur = (e: any) => {
    let data: any = { ...state };
    switch (e.target.name) {
      case "old_password": {
        data.isValidOldPassword =
          e.target.value.length > 0 && e.target.value.length <= 15;
        break;
      }
      case "new_password": {
        data.isValidNewPassword =
          e.target.value.length >= 8 &&
          e.target.value.length <= 15 &&
          validateBlankSpace(e.target.value) &&
          e.target.value !== data.old_password;
        break;
      }
      case "new_password_confirm": {
        data.isValidNewPasswordConfirm = validateConfirmPassword(
          e.target.value,
          data.new_password
        );
        break;
      }
    }
    setState(data);
  };

  const handleSubmit = () => {
    const credentials = EmailAuthProvider.credential(
      user?.email,
      state?.old_password
    );
    setLoader(true);
    const currentUser: any = getAuth().currentUser;
    if (state.new_password === state.new_password_confirm) {
      reauthenticateWithCredential(currentUser, credentials)
        .then(() => {
          updatePassword(currentUser, state.new_password)
            .then((response: any) => {
              console.log(response);
              toast.success("Password updated successfully!");
              setLoader(false);
              handleClose();
              setState({
                old_password: "",
                new_password: "",
                new_password_confirm: "",
                isValidOldPassword: true,
                isValidNewPasswordConfirm: true,
                isValidNewPassword: true,
              });
            })
            .catch((error) => {
              console.log(error);
              const errorCode = error.code;
              const errorMessage = error.message;
              toast.error(errorMessage);

              setLoader(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
          toast.error("Current password is incorrect");
        });
    } else {
      toast.error("Password does not match with the new password");
    }
  };

  return (
    <div>
      <DialogBox
        title="Change Password"
        open={open}
        handleClose={() => {
          handleClose();
          setState({
            old_password: "",
            new_password: "",
            new_password_confirm: "",
            isValidOldPassword: true,
            isValidNewPasswordConfirm: true,
            isValidNewPassword: true,
          });
        }}
      >
        <DialogContent sx={{ p: 5 }}>
          <Box sx={{ my: 1 }}>
            <InputPasswordField
              onBlur={handleBlur}
              onChange={handleChange}
              name={"old_password"}
              fullWidth={true}
              value={state.old_password}
              isLabel={true}
              label="Old Password"
              placeholder="Enter Password"
              isValid={state.isValidOldPassword}
              message={"Please enter your current password"}
            />
          </Box>
          <Box sx={{ my: 1 }}>
            <InputPasswordField
              onBlur={handleBlur}
              onChange={handleChange}
              name={"new_password"}
              fullWidth={true}
              value={state.new_password}
              isLabel={true}
              label="New Password"
              placeholder="Enter Password"
              isValid={state.isValidNewPassword}
              message={
                state.new_password === state.old_password
                  ? "New password cannot be same as old password"
                  : state.new_password.trim() === ""
                  ? "Please enter your new password"
                  : "Your Password must contain atleast 8 characters and must not exceed 15 characters without spaces"
              }
            />
          </Box>
          <Box sx={{ my: 1 }}>
            <InputPasswordField
              onBlur={handleBlur}
              onChange={handleChange}
              name={"new_password_confirm"}
              fullWidth={true}
              value={state.new_password_confirm}
              isLabel={true}
              label="Confirm Password"
              placeholder="Enter Password"
              isValid={state.isValidNewPasswordConfirm}
              message={
                state.new_password_confirm.trim() === ""
                  ? "Please enter your new password to confirm"
                  : "Your new password is not same as this password"
              }
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ my: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ height: "30px", width: "fit-content", m: 0 }}
            onClick={() => {
              handleClose();
              setState({
                old_password: "",
                new_password: "",
                new_password_confirm: "",
                isValidOldPassword: true,
                isValidNewPasswordConfirm: true,
                isValidNewPassword: true,
              });
              // clearUserFormData();
              // setOnClickUserForm(false);
            }}
          >
            Cancel
          </Button>
          <SubmitButton
            loading={loader}
            onClick={handleSubmit}
            variant="contained"
            style={{ height: "30px", width: "fit-content", m: 0 }}
            disabled={disabled}
            text="Reset Password"
          />
        </DialogActions>
      </DialogBox>
    </div>
  );
};

export default ChangePassword;
