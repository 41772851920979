import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { StyledDropdown } from "./style";
import { Typography } from "@mui/material";
import { sosColors } from "../../constants/theme/colors";
import "./style.css";
import _ from "lodash";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps: any = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  selectionList: any;
  value?: object;
  handleChange: Function;
  placeholder?: string;
}

function getStyles(name: string, personName?: any, theme?: Theme) {
  return {
    fontWeight:
      personName?.name === name
        ? theme?.typography.fontWeightRegular
        : theme?.typography.fontWeightMedium,
  };
}

const DropDown = (props: Props) => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<any>({});

  React.useEffect(() => {
    setPersonName(props.value);
  }, [props.value]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setPersonName(value);
    props.handleChange(value);
  };
  return (
    <div>
      <FormControl sx={{ width: 1 }}>
        <StyledDropdown
          displayEmpty
          value={personName}
          onChange={handleChange}
          input={
            <OutlinedInput
              sx={{
                "&.MuiInputBase-root": {
                  borderColor: _.isEmpty(personName)
                    ? `${sosColors["divider"]} !important`
                    : `${sosColors["ascent2"]} !important`,
                },
              }}
            />
          }
          renderValue={(selected: any) => {
            if (Object.keys(selected).length === 0) {
              return (
                <Typography
                  variant="regular_12px"
                  sx={{ color: `${sosColors["placeholder"]} !important` }}
                >
                  {props.placeholder || "Placeholder"}
                </Typography>
              );
            }

            return selected.name;
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="" sx={{ display: "none" }}>
            {props.placeholder || "Placeholder"}
          </MenuItem>
          {props.selectionList.map((item: any) => (
            <MenuItem
              key={item.id}
              value={item}
              style={getStyles(item.name, personName, theme)}
            >
              <Typography variant="regular_12px" color={sosColors["text"]}>
                {item?.name}
              </Typography>
            </MenuItem>
          ))}
        </StyledDropdown>
      </FormControl>
    </div>
  );
};
export default DropDown;
