export const REGEX = {
  // eslint-disable-next-line
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // eslint-disable-next-line
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*^?&\.])[A-Za-z\d@#$!%*^?&\.]{8,15}$/,
  CONTACT:
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
  NAME: /^(?!\s)(?![\s\S]*\s$)[A-Za-z ]+$/,
  USERNAME: /^[a-zA-Z '.-]*$/,
  ONLY_TEN_DIGIT: /^[0-9]{10}$/,
  NOT_BLANK_SPACE: /^[^\s]+$/,
  ONLY_NO: /^\d+$/,
  // eslint-disable-next-line
  ONLY_CHAR: /^[0-9a-zA-Z\_-]$/,
  // eslint-disable-next-line
  PINCODE: /^(\d{5})$/,
  URL: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  ALPHA_NUMERIC: /^([a-zA-Z0-9@&.,_ -]+)$/,
  // eslint-disable-next-line
  SPECIAL_CHAR: /^[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
  FAX: /^(\+?\d{1,}(\s?|\-?)\d*(\s?|\-?)\(?\d{2,}\)?(\s?|\-?)\d{3,}\s?\d{3,})$/g,
};
