import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AuthenticationLayout from "../../layout/AuthenticationLayout";
import {
  InputField,
  InputPasswordField,
} from "../../constants/InputField/input";
import { sosColors } from "../../constants/theme/colors";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../utils/routes";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../firebase-config";
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import SubmitButton from "../../components/SubmitButton";
import { toast } from "react-toastify";
import { validateEmail, validateValue } from "../../utils/validation";

export interface state {
  email: string;
  isValidEmail: boolean;
}

const SendEmail = () => {
  const navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const auth: any = getAuth(app);
  const [formData, setFormData] = useState<state>({
    email: "",
    isValidEmail: true,
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, [document.body]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let FormData: any = { ...formData };
    FormData[e.target.name] = e.target.value;
    setFormData(FormData);
  };

  const handleSubmit = () => {
    setLoader(true);
    sendPasswordResetEmail(auth, formData.email)
      .then(() => {
        setLoader(false);
        navigate(ROUTE_PATH.login);
        toast.success("Password reset mail sent successfully");
      })
      .catch((error) => {
        console.log(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage);
        setLoader(false);
      });
  };

  const handleOnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let FormData: any = { ...formData };

    FormData.isValidEmail = validateEmail(e.target.value);
    setFormData(FormData);
  };

  return (
    <AuthenticationLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "75%" }}>
          <InputField
            onBlur={handleBlur}
            name={"email"}
            onChange={handleChange}
            fullWidth={true}
            isLabel={true}
            label="Email id"
            placeholder="Enter here"
            isValid={formData.isValidEmail}
            onKeyPress={handleOnKeyDown}
            message={
              formData.email === ""
                ? "Please enter an email"
                : "Please enter a valid email"
            }
          />
        </Box>

        <Box sx={{ width: "75%", mt: "30px" }}>
          <SubmitButton
            disabled={!(formData.email !== "" && formData.isValidEmail)}
            variant="contained"
            onClick={() => handleSubmit()}
            style={{ height: "51px" }}
            loading={loader}
            text="Send Password Reset Link"
          />
        </Box>
      </Box>
    </AuthenticationLayout>
  );
};

export default SendEmail;
