import { Button, CircularProgress } from "@mui/material";
import React from "react";

export interface Props {
  className?: string;
  onClick?: any;
  loading?: boolean;
  disabled?: boolean;
  text?: string;
  variant?: any;
  style?: any;
}

const SubmitButton = ({
  className = "",
  onClick = () => {},
  loading = false,
  disabled = false,
  text = "",
  variant = "contained",
  style = {},
}: Props) => {
  return (
    <div>
      <Button
        sx={style}
        className={className}
        disabled={disabled || loading}
        variant={variant}
        onClick={onClick}
      >
        {loading ? (
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            sx={{ color: "white" }}
          />
        ) : (
          text
        )}
      </Button>
    </div>
  );
};

export default SubmitButton;
