/** @format */

import { createTheme } from "@mui/material/styles";
import { sosColors, themePalette } from "./colors";

export const themeButtons = createTheme(themePalette, {
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        variant: "contained",
        color: "primary",
      },
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontFamily: "Roboto , sans-serif",
          margin: "auto",
          borderRadius: "5px",
          color: sosColors["white"],
          width: "100%",
          minWidth: "30px",
          fontSize: "14px !important",
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            backgroundColor: sosColors["ascent2"],

            "&:hover": {
              backgroundColor: sosColors["ascent2"],
              boxShadow: "0px 2px 4px rgba(87, 72, 63, 0.3)",
            },
            "&:focus": {
              backgroundColor: sosColors["ascent2"],
            },
            "&:disabled": {
              backgroundColor: sosColors["disableBG"],
              color: "white",
            },
            "&:active": {
              backgroundColor: sosColors["ascent2"],
              boxShadow: "0px 2px 4px rgba(87, 72, 63, 0.3)",
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            borderColor: sosColors["ascent2"],
            color: sosColors["ascent2"],
            lineHeight: "normal",
            letterSpacing: "0.43px",

            "&:hover": {
              backgroundColor: sosColors["ascent2"],
              color: sosColors["white"],
            },
            // "&:focus": {
            //   backgroundColor: sosColors["ascent2"],
            //   color: sosColors["white"],
            // },
            "&:disabled": {
              backgroundColor: "transparent",
              color: sosColors["ascent2"],
              borderColor: sosColors["disableBG"],
            },
            "&:active": {
              color: sosColors["ascent2"],
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            backgroundColor: sosColors["ascent1"],

            lineHeight: "normal",
            letterSpacing: "0.43px",

            "&:hover": {
              backgroundColor: sosColors["ascent1"],
              color: sosColors["white"],
              borderColor: sosColors["ascent1"],
            },
            "&:focus": {
              backgroundColor: sosColors["ascent1"],
            },
            "&:disabled": {
              backgroundColor: "none",
              color: sosColors["ascent1"],
              borderColor: sosColors["disableBG"],
            },
            "&:active": {
              color: sosColors["ascent1"],
            },
          },
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: {
            borderColor: sosColors["ascent1"],
            color: sosColors["ascent1"],
            lineHeight: "normal",
            letterSpacing: "0.43px",

            "&:hover": {
              // backgroundColor: sosColors["ascent1"],
              color: sosColors["white"],
              borderColor: sosColors["ascent1"],
            },
            // "&:focus": {
            //   backgroundColor: "transparent",
            // },
            "&:disabled": {
              backgroundColor: "none",
              color: sosColors["ascent1"],
              borderColor: sosColors["disableBG"],
            },
            "&:active": {
              color: sosColors["ascent1"],
            },
          },
        },
      ],
    },
  },
});
