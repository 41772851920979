import * as XLSX from "xlsx";

// Function to convert the JSON(Array of objects) to CSV.
const arrayToCsv = (headers, data) => {
  const csvRows = [];
  // getting headers.
  const headerValues = headers.map((header) => header.label);
  csvRows.push(headerValues.join("\t")); // Push into array as comma-separated values
  // Getting rows.
  for (const row of data) {
    const rowValues = headers.map((header) => {
      // Handle arrays by joining them into a single string
      const value = Array.isArray(row[header.key])
        ? row[header.key].join("\t ")
        : row[header.key];
      return `${value}`.replace(/"/g, ""); // Remove double quotes
    });
    csvRows.push(rowValues.join("\t")); // Push into array as comma-separated values.
  }
  return csvRows.join("\n"); // To enter the next rows in the new line '\n'
};

// Function to download the generated CSV as a .csv file.
const download = (data, fileName) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", fileName + ".csv");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// Assuming allcoments is an array of strings in your data
export const generateCSV = (header, data, filename) => {
  // Process each row to correctly handle allcoments array
  const dataWithComments = data.map((row, index) => ({
    ...row,
    allcoments: row?.allcoments !== undefined ? row?.allcoments?.join(";") : "",
    // address_line_1: row.address_line_1.replaceAll(",", " "),
  }));
  const csvData = arrayToCsv(header, dataWithComments);
  downloadxls(csvData, filename);
};

const downloadxls = (csvData, filename) => {
  const jsonData = CSVtoJSON(csvData);
  let ws = XLSX.utils.json_to_sheet(jsonData);
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "sheet");
  let buf = XLSX.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
  let str = XLSX.write(wb, { bookType: "xlsx", type: "binary" }); // generate a binary string in the web browser
  XLSX.writeFile(wb, `${filename}.xlsx`);
};

// Function to convert CSV to JSON
const CSVtoJSON = (csv) => {
  const lines = csv.split("\n");
  const result = [];
  const headers = lines[0].split("\t");
  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentline = lines[i].split("\t");
    for (let j = 0; j < headers.length; j++) {
      // Check if the value is enclosed in double quotes
      if (currentline[j]?.startsWith('"') && currentline[j]?.endsWith('"')) {
        // Remove the quotes and assign the value
        obj[headers[j]] = currentline[j]?.slice(1, -1);
      } else {
        obj[headers[j]] = currentline[j];
      }
    }
    result.push(obj);
  }
  return result;
};

