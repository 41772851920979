import React, { useEffect, useState } from "react";
import SubmitButton from "../SubmitButton";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import DialogBox from "../DialogBox";
import { DatePickerComponent } from "../DateRangePicker/style";
import date_picker_icon from "../../assets/icons/date_picker_icon.svg";
import { sosColors } from "../../constants/theme/colors";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { renderDigitalClockTimeView } from "@mui/x-date-pickers";
import { StyledTimePicker } from "./style";
import "./style.css";
import { SmallInputField } from "../../constants/InputField/input";
import moment from "moment";
import { saveReminders } from "../../services";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

interface Props {
  open: boolean;
  handleClose: Function;
  leadData: any;
  fetchData: Function;
}

function AddReminders(props: Props) {
  const params = useParams();
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
    const [comment, setComment] = useState("");
    const [commentValid, setCommentValid] = useState(true);
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
      if (
        date &&
        time &&
        comment !== "" &&
        commentValid &&
        moment(date).format() !== "Invalid date" &&
        moment(time).format() !== "Invalid date"
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }, [date, time, comment, commentValid]);

    const DatePickerIcon = () => {
      return <img src={date_picker_icon} alt="date_picker" />;
    };

    const getCombinedDateObject = (date: any, time: any) => {
      let dateTime: string = "";
      if (date && time) {
        let DateObj: any = moment(date, "MM-DD-YYYY").format("YYYY-MM-DD");
        let Time = moment(time).format("HH:mm:ss");

        dateTime = DateObj + " " + Time;
      }

      let finalDateTime = new Date(dateTime.replace(/-/g, "/"));

      if (isNaN(finalDateTime.getTime())) return null;
      else return finalDateTime;
    };

    const clearData = () => {
      setDate(null);
      setTime(null);
      setComment("");
    };
  const checkMinutes = (timeStr: any) => {
    const minutes = moment(timeStr, "hh:mm A").minutes();

    if (minutes === 15 || minutes === 30 || minutes === 45 || minutes === 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleSubmit = () => {
    if (
      moment(getCombinedDateObject(date, time)).format() > moment().format() &&
      checkMinutes(moment(getCombinedDateObject(date, time)).format("hh:mm A"))
    ) {
      setLoader(true);
      saveReminders({
        date_time: moment(getCombinedDateObject(date, time)).utc().format(),
        local_date: moment(getCombinedDateObject(date, time)).format(
          "DD MMM YYYY"
        ),
        local_time: moment(getCombinedDateObject(date, time)).format("hh:mm A"),
        comment: comment,
        user_id: props.leadData?.sales_person.id,
        lead_id: params?.id,
      })
        .then((response: any) => {
          if (response.status === 201 && response.data.status_code === 201) {
            toast.success("Reminder set for this lead");
            clearData();
            setLoader(false);
            props.fetchData();
            props.handleClose();
          } else {
            setLoader(false);
            toast.success(response?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    } else {
      toast.error("Please select a valid date and time");
    }
  };

    const handleNoteChange = (e: any) => {
      e.preventDefault();
      setComment(e.target.value);
    };

    const handleNodeBlur = (e: any) => {
      setCommentValid(
        e.target.value.length <= 5000 && e.target.value.length >= 1
      );
    };

  
  return (
    <div>
      <DialogBox
        title="Add New Reminder"
        open={props.open}
        handleClose={() => {
          clearData();
          props.handleClose();
        }}
      >
        <DialogContent sx={{ p: "0px !important" }}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Typography
                  variant="regular_13px"
                  color={sosColors["secondary"]}
                  sx={{ mb: 1 }}
                >
                  Date
                </Typography>
                <DatePickerComponent
                  disablePast={true}
                  slots={{
                    openPickerIcon: DatePickerIcon,
                  }}
                  onChange={(e: any) => setDate(e)}
                  sx={{ width: "96% !important" }}
                  value={date}
                />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
                id="timePicker"
              >
                <Typography
                  variant="regular_13px"
                  color={sosColors["secondary"]}
                  sx={{ mb: 0.8 }}
                >
                  Time
                </Typography>
                <DemoContainer
                  components={["TimePicker"]}
                  sx={{
                    paddingTop: "1px",
                    overflow: "hidden",
                  }}
                >
                  <StyledTimePicker
                    onChange={(e: any) => setTime(e)}
                    minutesStep={15}
                    viewRenderers={{
                      hours: renderDigitalClockTimeView,
                      minutes: null,
                      seconds: null,
                    }}
                    value={time}
                  />
                </DemoContainer>
              </Box>
            </Box>
          </Box>
          <Box sx={{ px: 2, pb: 2 }}>
            <SmallInputField
              name="comment"
              fullWidth={true}
              onChange={(e: any) => handleNoteChange(e)}
              isLabel={true}
              isValid={commentValid}
              onBlur={handleNodeBlur}
              label="Comment"
              placeholder="Type here"
              labelVariant="regular_13px"
              rows={5}
              multiline={true}
              value={comment}
              message="Please enter a comment of minimum 1 character and maximum 5000 characters"
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ my: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ height: "30px", width: "fit-content", m: 0 }}
            onClick={() => {
              clearData();
              props.handleClose();
            }}
          >
            Cancel
          </Button>
          <SubmitButton
            disabled={disabled}
            loading={loader}
            onClick={handleSubmit}
            variant="contained"
            style={{ height: "30px", width: "fit-content", m: 0 }}
            text={"Done"}
          />
        </DialogActions>
      </DialogBox>
    </div>
  );
}

export default AddReminders;
