let apiVersion = process.env.REACT_APP_BE_URL;
let env = process.env.REACT_APP_ENV;
const stageUrl = (name) => {
  return `https://${name}-jw37mqg7dq-uc.a.run.app`;
};

const prodUrl = (name) => {
  return `https://${name}-g6oqy36olq-uc.a.run.app`;
};

const formatWithName = (name) => {
  return env === "LOCAL"
    ? `${apiVersion}/${name}`
    : process.env.REACT_APP_BE_URL.replace("{{name}}", name.toLowerCase());
};

//users
export const getAllUsersUrl = formatWithName("getAllUsers");

export const addUserUrl = formatWithName("addUser");

export const updateUserUrl = formatWithName("updateUser");

export const deleteUserUrl = formatWithName("deleteUser");

export const updateLeadSalesPersonUrl = formatWithName("updateLeadSalesPerson");

// Leads
export const getAllLeadsUrl = formatWithName("getAllLeads");

export const getAllLeadsCountUrl = formatWithName("getAllLeadsCount");

export const getAllUnPaginatedLeadsUrl = formatWithName(
  "getAllUnPaginatedLeads"
);

export const addLeadUrl = formatWithName("addLead");

export const updateLeadUrl = formatWithName("updateLead");

export const getSingleLeadUrl = formatWithName("getSingleLead");

export const addNotesUrl = formatWithName("addNotes");

export const getLeadNotesUrl = formatWithName("getLeadNotes");

export const deleteNoteUrl = formatWithName("deleteNote");

//status
export const getStatusListUrl = formatWithName("getStatusList");

export const updateStatusUrl = formatWithName("updateStatus");

//project type
export const getProjectTypeListUrl = formatWithName("getProjectTypeList");

// reminders
export const saveReminderUrl = formatWithName("saveReminder");

export const getLeadRemindersUrl = formatWithName("getLeadReminders");

export const deleteReminderUrl = formatWithName("deleteReminder");